export default {
    userusertitle: 'User',
    menuusertitle: 'User',
    editusertitle: 'Edit user',
    newusertitle: 'New user',
    userusersssubtitle: 'Users',
    userusersstrashsubtitle: 'Deleted users',
    wapplruserappusersname: 'Users',
    wapplruserappuserstitle: 'User users',
    wapplruserappuserstrash: 'Deleted users',

    menulogintitle: 'Login',
    menuloginname: 'Login',

    menusociallogintitle: 'Login with another services',
    wapplrloginapploginname: 'Login',
    wapplrloginappsignupname: 'Signup',
    wapplrloginapplogintitle: 'Login',
    wapplrloginappsignuptitle: 'Sign up',
    wapplrloginappforgotname: 'Forgot password',
    wapplrloginappforgottitle: 'Forgot password',
    wapplrloginappresettitle: 'Reset password',
    wapplrloginapplogoutname: 'Logout',
    wapplrloginappfacebookname: 'Facebook',
    wapplrloginappgooglename: 'Google',

    wapplrloginappemail: 'Email',
    wapplrloginapppassword: 'Password',
    wapplrloginappnewpassword: 'New password',
    wapplrloginappconfirmpassword: 'Password confirmation',
    wapplrloginapploginsubmit: 'Login',
    wapplrloginapplastname: 'Lastname',
    wapplrloginappfirstname: 'Firstname',
    wapplrloginappsignupsubmit: 'Sign up',
    wapplrloginappforgotsubmit: 'Send confirmation email',
    wapplrloginappavatarsubmit: 'Save new profile picture',
    wapplrloginappresetsubmit: 'Save new password',
    wapplrloginappchangeemailsubmit: 'Save email address',
    wapplrloginappconfirmsendsubmit: 'Resend confirmation email',
    wapplrloginappbasicsubmit: 'Save',

    wapplrloginappconfirmsendname: 'Email confirmation',
    wapplrloginappconfirmsendtitle: 'Email confirmation',

    wapplrloginappconfirmationtitle: 'Email confirmation',
    wapplrloginappconfirmationisvalidated: 'Your email address was validated',
    wapplrloginappconfirmationmissingkeytext: 'Missing key',
    wapplrloginappconfirmationprocessingtext: 'Validating your email...',
    wapplrloginappconfirmationloggedouttext: 'Please log in before you trying email validation',
    wapplrloginappconfirmsendloggedouttext: 'Please log in before you trying send confirmation email to validate your email address',

    wapplrloginappmincharerror: function(a) {
        return 'Minimum ' + a + ' character'
    },
    wapplrloginappemailerror: 'Please add a valid email',
    wapplrloginappspecialwordserror: 'Please only use letters',
    wapplrloginapppasswordequalerror: 'Password does not match the confirm password',

    wapplrloginappconfirmationsubtitle: 'Confirmation your email address',
    wapplrloginapploginsubtitle: 'Login with email',
    wapplrloginappconfirmsendsubtitle: 'Send confirmation email to validate your email address',
    wapplrloginappforgotsubtitle: 'Send confirmation email to set new password',
    wapplrloginappresetsubtitle: 'Set your new password',
    wapplrloginappsignupsubtitle: 'Signup with email',

    wapplrloginappresetmissingkeytext: 'Missing key',

    wapplrloginapploginheadertitle: 'Login',
    wapplrloginappsignupheadertitle: 'Signup',
    wapplrloginapplogoutheadertitle: 'Account',
    wapplrloginappconfirmheadertitle: 'Confirm email',
    wapplrloginappforgotheadertitle: 'Forgot password',
    wapplrloginappresetheadertitle: 'Reset password',

    wapplrloginapplogoutbuttontext: 'Logout',

    wapplrloginappchangepasssubtitle: 'Set your new password',
    wapplrloginappchangepassloggedouttext: 'Please log in before you trying change password',
    wapplrloginappchangeemailsubtitle: 'Set your new email',
    wapplrloginappchangeemailloggedouttext: 'Please log in before you trying change email',
    wapplrloginappchangebasicsubtitle: 'Set your basic data',
    wapplrloginappchangebasicloggedouttext: 'Please log in before you trying change your data',
    wapplrloginappchangepassheadertitle: 'Change password',
    wapplrloginappchangeemailheadertitle: 'Change email',
    wapplrloginappchangebasicheadertitle: 'Your basic data',
    wapplrloginappchangeavatarsubtitle: 'Set your new profile picture',
    wapplrloginappsocialsubtitle: 'Login with another services',

    wapplrloginappchangepasswordtitle: 'Change password',
    wapplrloginappchangeemailwordtitle: 'Change email',
    wapplrloginappchangebasicwordtitle: 'Change basic data',

    wapplrloginappchangepassname: 'Change password',
    wapplrloginappchangeemailname: 'Change email',
    wapplrloginappchangeavatarname: 'Change profile picture',
    wapplrloginappchangebasicname: 'Change name',

    wapplruserappsettingsname: 'Settings',
    wapplruserappsettingstitle: 'Settings',
    wapplruserappprofilename: 'Profile',
    wapplruserappdahsboardname: 'Dashboard',
    accountsettings: 'Account settings',

    signfail: 'Sorry, there was an issue signing you in, please try again',
    emailfail: 'Sorry, there was an issue sending email out, please try again',
    incorrectpassword: 'Incorrect password',
    incorrectemail: 'Incorrect email',
    incorrectemailfacebook: 'If you use email of facebook service, please login with your facebook account',

    forgotfail: 'Sorry, there was an issue sending email to you, please try again',
    forgotsuccess: 'The confirmation email has been sent to your email address',

    resetfail: 'Sorry, there was an issue saving new password, please try again',
    resetsuccess: 'The new password has been saved',
    incorrectresetdata: 'Your email or your reset key is incorrect',

    confirmsendfail: 'Sorry, there was an issue sending email to you, please try again',
    confirmsendsuccess: 'The confirmation email has been sent to your email address',

    confirmationfail: 'Sorry, there was an issue validating your email, please try again',
    confirmationsuccess: 'Your email address was confirmed',
    confirmationkeyfail: 'Invalid key, please try again',

    saveemailfail: 'Sorry, there was an issue saving your email, please try again',
    saveemailsuccess: 'The new email has been saved',

    saveavatarfail: 'Sorry, there was an issue saving your profile picture, please try again',
    saveavatarsuccess: 'The new profile picture has been saved',

    saveusertypefail: 'Sorry, there was an issue saving your registration type, please try again',
    saveusertypesuccess: 'Your registration type has been saved',

    basicdatafail: 'Sorry, there was an issue saving data, please try again',
    basicdatasuccess: 'The new data has been saved',

    deleteaccountfail: 'Sorry, there was an issue deleting your account, please try again',
    deleteaccountsuccess: 'Your account has been deleted',

    usedemail: 'Email was already used',
    nochange: 'There was no change',

    wapplruserappprofiletitle: 'Profile',

    wapplrloginappdeleteaccountsubtitle: 'Delete account (the account can be restore for 30 days if the user login the system)',
    wapplrloginappdeleteaccountloggedouttext: 'Please log in before you try delete your account',
    wapplrloginappdeleteaccountheadertitle: 'Delete',
    wapplrloginappdeleteaccounttitle: 'Delete account',
    wapplrloginappdeleteaccountsubmit: 'Delete',
    wapplrloginappdeleteaccountname: 'Delete account',

    userstatusregistrated: 'registarted',
    userstatusdeleteditself: 'deleted by user',
    userstatusbanned: 'user banned permanently',
    userstatusrequireddata1: '!required data & email validated',
    userstatusrequireddata2: 'required data & !email validated',
    userstatusrequireddata3: 'required data are available',
    userstatusapproves: 'user approved',

    stepuserbasic: 'Basic data',
    stepuseravatar: 'profile picture',

    deleteusersubtitle: 'Delete user',
    deleteuserquestion: 'Are you sure want to delete your user? (as delete itself)',

    saveuserdefaultfail: 'Sorry, there was an issue save user, please try again',
    saveusersuccessmessage: 'User data has been saved',
    deleteuserdefaultfail: 'Sorry, there was an issue delete user, please try again',
    deleteusersuccessmessage: 'User has been deleted',

    facebookmissingemail: 'This service (Facebook) did not send return your email, but to our system required it. Please, try signup with your email!',

    usersearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Search in users'
        if (username && !taxs) r = 'Search in ' + username + '\'s users'
        if (!username && taxs) r = 'Search in users in ' + taxs + ' category'
        if (username && taxs) r = 'Search in ' + username + '\'s users, and in ' + taxs + ' category'
        return r
    },

    metaregistrateddate: 'Registrated date',

    approveterms: 'Approve Terms and Conditions',

    approveterms1: 'I read and I agree the content of the ',
    approveterms2: 'Terms and Conditions',
    approveterms3: ' and the ',
    approveterms4: 'Privacy',
    approveterms5: '',
    approveterms6: 'Newsletter: I consent to the processing of my personal data for the purpose of sending newsletters.',
    approvetermsreq: 'Required',

}
