export default function(c) {

    const forgotdatawhenloggedin = c.forgotdatawhenloggedin || [
        {
            type: 'button', attr: {
                type: 'submit',
                name: 'submit',
                label: function(state) {
                    return (state.processing) ? 'Sending...' : 'Send confirmation email to set new password'
                },
                style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const confirmsenddata = c.confirmsenddata || [
        {
            type: 'button', attr: {
                type: 'submit',
                name: 'submit',
                label: function(state) {
                    return (state.processing) ? 'Sending...' : 'Resend confirmation email to validate your email address'
                },
                style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const changepassdata = c.changepassdata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'new_password',
                hintText: 'New password',
                floatingLabelText: 'New password',
                validations: { minLength: 6 },
                validationError: 'Minimum 6 character',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'repeated_password',
                hintText: 'Confirm new password',
                floatingLabelText: 'Confirm new password',
                validations: 'equalsField:new_password',
                validationError: 'Password does not match the confirm password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save new password'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const changeemaildata = c.changeemaildata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text', attr: {
                name: 'email',
                value: function(state, props) {
                    const { construct } = props
                    const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                    const user = (globalstate && globalstate.response && globalstate.response.user) ? globalstate.response.user : null
                    return (user && user.email) ? user.email : ''
                },
                hintText: 'Email',
                floatingLabelText: 'Email',
                validations: 'isEmail',
                validationError: 'Please add a valid email',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save new email'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const changebasicdata = c.changebasicdata || [
        {
            type: 'text', attr: {
                name: 'lastname',
                value: function(state, props) {
                    const { construct } = props
                    const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                    const user = (globalstate && globalstate.response && globalstate.response.user) ? globalstate.response.user : null
                    return (user && user.name && user.name.last) ? user.name.last : ''
                },
                hintText: 'Lastname',
                floatingLabelText: 'Lastname',
                validations: 'isSpecialWords',
                validationError: 'Please only use letters',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text', attr: {
                name: 'firstname',
                value: function(state, props) {
                    const { construct } = props
                    const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                    const user = (globalstate && globalstate.response && globalstate.response.user) ? globalstate.response.user : null
                    return (user && user.name && user.name.first) ? user.name.first : ''
                },
                hintText: 'Firstname',
                floatingLabelText: 'Firstname',
                validations: 'isSpecialWords',
                validationError: 'Please only use letters',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save data'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const deleteaccountdata = c.deleteaccountdata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Deleting...' : 'Delete'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    function forgot(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function confirmsend(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function changepass(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function changeemail(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function changebasic(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function deleteaccount(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    if (c.forgot) forgot = c.forgot
    if (c.confirmsend) confirmsend = c.confirmsend
    if (c.changepass) changepass = c.changepass
    if (c.changeemail) changeemail = c.changeemail
    if (c.changebasic) changebasic = c.changebasic

    if (c.deleteaccount) deleteaccount = c.deleteaccount

    return {
        forgotsubmit: forgot,
        confirmsendsubmit: confirmsend,
        changepasssubmit: changepass,
        changeemailsubmit: changeemail,
        changebasicsubmit: changebasic,
        deleteaccountsubmit: deleteaccount,

        forgotdatawhenloggedin,
        confirmsenddata,
        changepassdata,
        changeemaildata,
        changebasicdata,
        deleteaccountdata,

        confirmationisvalidated: c.confirmationisvalidated,
        confirmsendsubtitle: c.confirmsendsubtitle,
        forgotsubtitle: c.forgotsubtitle,
        changepasssubtitle: c.changepasssubtitle,
        changeemailsubtitle: c.changeemailsubtitle,
        changebasicsubtitle: c.changebasicsubtitle,
        deleteaccountsubtitle: c.deleteaccountsubtitle

    }

};
