import React from 'react'
import createReactClass from 'create-react-class'
import wapplrcomponents from '../../../../components'
import TemplateDefault from '../../../templates/template_default'

import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import fetchs from '../../../services/fetchs'

import { getMinUserStatusWhenAllAccess } from '../../../server/userstatus/statuses'

import NotFound from '../../../components/NotFound'
import nfs from '../../../components/NotFound/default.css'

const Wapplr = wapplrcomponents.wapplr

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        const { response } = this.props
        const user = this.getUser(response)

        return {
            isMounted: false,
            user: user || null
        }
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getUser: function(response) {
        return (response && response.user) ? response.user : null
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    getLink: function(type, p) {

        const { routes } = this.props

        const {
            menuuserslug = '/user'
        } = routes

        if (type === 'user' && p.id) return { pathname: menuuserslug, search: 'user=' + p.id }
        if (this.state && this.state.user && this.state.user.id) return {
            pathname: menuuserslug,
            search: 'user=' + this.state.user.id
        }
        return { pathname: '/' }
    },
    componentDidMount: function() {

        const { response, history, curlang = {}, wapplruserfunctions, updatefunctions } = this.props
        const user = this.getUser(response)

        const {

            forgotfail = 'Sorry, there was an issue sending email to you, please try again',
            forgotsuccess = 'The confirm email has been sent to your email address',

            confirmsendfail = 'Sorry, there was an issue sending email to you, please try again',
            confirmsendsuccess = 'The confirm email has been sent to your email address',

            resetfail = 'Sorry, there was an issue saving new password, please try again',
            resetsuccess = 'The new password has been saved',

            saveemailfail = 'Sorry, there was an issue saving email, please try again',
            saveemailsuccess = 'The new email has been saved',

            basicdatafail = 'Sorry, there was an issue saving data, please try again',
            basicdatasuccess = 'The new data has been saved',

            deleteaccountfail = 'Sorry, there was an issue delete your acocunt, please try again',
            deleteaccountsuccess = 'Your account has been deleted'

        } = curlang


        const historyPush = this.historyPush
        const snack = this.snack

        wapplruserfunctions.forgot = fetchs.forgot({
            getHelpers: this.getHelpers,
            history: history,
            replacefromstate: { user: { email: 1 } },
            success: function() {
                snack(forgotsuccess)
            },
            fail: function({ response, invalidateForm }) {
                const field = response.field || ''
                const message = response.message || forgotfail
                if (field === 'snack') {
                    if (message) snack(message)
                } else {
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplruserfunctions.confirmsend = fetchs.confirmsend({
            getHelpers: this.getHelpers,
            history: history,
            replacefromstate: { user: { email: 1 } },
            success: function() {
                snack(confirmsendsuccess)
            },
            fail: function({ response, invalidateForm }) {
                let field = response.field || ''
                const message = response.message || confirmsendfail
                if (field === 'snack') {
                    if (message) snack(message)
                } else {
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplruserfunctions.changepass = fetchs.changepass({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                snack(resetsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(window.location.pathname)
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'repeated_password'
                    const message = response.message || resetfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplruserfunctions.changeemail = fetchs.changeemail({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                snack(saveemailsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(window.location.pathname)
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'email'
                    const message = response.message || saveemailfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplruserfunctions.changebasic = fetchs.changebasic({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                snack(basicdatasuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(window.location.pathname)
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'lastname'
                    const message = response.message || basicdatafail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplruserfunctions.deleteaccount = fetchs.deleteaccount({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                snack(deleteaccountsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush('/')
                            }
                        })
                    } else {
                        window.location.href = '/'
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'password'
                    const message = response.message || deleteaccountfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.setState({
            isMounted: true,
            user: user
        })
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },
    show: function() {
        const { response, route } = this.props
        const user = this.getUser(response)
        const me = this.getMe()
        const minuserstatus = getMinUserStatusWhenAllAccess() - 1
        if (user && user.id && me && me.id === user.id || me && me.isAdmin || me && me.isEditor) return true
        if (route === '/settings') {
            return !!(user && user.id && me && me.id === user.id || me && me.isAdmin || me && me.isEditor)
        }
        return !!(user && user.id && user.status > minuserstatus)

    },
    render: function() {

        const { history, wapplruserapp, curlang = {}, routes = {} } = this.props
        const { state } = this.getHelpers()
        const setRef = this.setRef
        const show = this.show()
        const me = this.getMe()

        return (
            <TemplateDefault {...this.props}>
                <div>
                    <div>
                        {(show) ? <Wapplr {...this.props} app={wapplruserapp} state={state} />
                            :
                            <NotFound
                                style={nfs}
                                history={history}
                                notFoundText={curlang.notfoundtext}
                                notFoundDesc={curlang.notfounddesc}
                                loggedOutDesc={(me && me.id) ? null : curlang.loggedoutdesc}

                                backLabel={curlang.gotobacktoprev}
                                homeLabel={curlang.gotohomepage}
                                loginLabel={(me && me.id) ? null : curlang.gotologin}
                                signupLabel={(me && me.id) ? null : curlang.gotosignup}
                                profileLabel={(me && me.id) ? curlang.gotoprofile : null}

                                homeHref={'/'}
                                loginHref={(me && me.id) ? null : (typeof window !== 'undefined' && window && window.location && window.location.pathname) ? routes.menuloginslug + '?callback=' + window.location.pathname : routes.menuloginslug}
                                signupHref={(me && me.id) ? null : (typeof window !== 'undefined' && window && window.location && window.location.pathname) ? routes.menuloginslug + routes.wapplrloginappsignupslug + '?callback=' + window.location.pathname : routes.menuloginslug + routes.wapplrloginappsignupslug}
                                profileHref={(me && me.id) ? routes.menuuserslug + '/' + me.id : null}

                            />
                        }
                    </div>
                </div>
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
            </TemplateDefault>
        )
    }
})
