export default function() {

    return [
        {
            path: '/login',
            data: {
                content: 'login'
            }
        }, {
            path: '/signup',
            data: {
                content: 'signup'
            }
        }, {
            path: '/forgot',
            data: {
                content: 'forgot'
            }
        }, {
            path: '/reset',
            data: {
                content: 'reset'
            }
        }, {
            path: '/confirmation/send',
            data: {
                content: 'confirmsend'
            }
        }, {
            path: '/confirmation',
            data: {
                content: 'confirmation'
            }
        }, {
            path: '/changepassword',
            data: {
                content: 'changepassword'
            }
        }, {
            path: '/changeemail',
            data: {
                content: 'changeemail'
            }
        }, {
            path: '/changebasic',
            data: {
                content: 'changebasic'
            }
        }, {
            path: '/deleteaccount',
            data: {
                content: 'deleteaccount'
            }
        }, {
            path: '*',
            data: {
                content: 'login',
                callback: async function({ r, universalPath }) {
                    if (universalPath === '/') return r
                    r.status = 404
                    r.title = 'Not found'
                    return r
                }
            }
        }
    ]
}
