export default {
    userusertitle: 'Felhasználó',
    menuusertitle: 'Felhasználó',
    editusertitle: 'Felhasználó szerkesztése',
    newusertitle: 'Új felhasználó',
    userusersssubtitle: 'Felhasználók',
    userusersstrashsubtitle: 'Törölt felhasználók',
    wapplruserappusersname: 'Felhasználók',
    wapplruserappuserstitle: 'Felhasználó felhasználói',
    wapplruserappuserstrash: 'Törölt felhasználók',

    menulogintitle: 'Belépés',
    menuloginname: 'Belépés',

    menusociallogintitle: 'Belépés más szolgáltatáskkal',
    wapplrloginapploginname: 'Belépés',
    wapplrloginappsignupname: 'Regisztráció',
    wapplrloginapplogintitle: 'Belépés',
    wapplrloginappsignuptitle: 'Regisztráció',
    wapplrloginappforgotname: 'Elfelejtett jelszó',
    wapplrloginappforgottitle: 'Elfelejtett jelszó',
    wapplrloginappresettitle: 'Új jelszó',
    wapplrloginapplogoutname: 'Kilépés',
    wapplrloginappfacebookname: 'Facebook',
    wapplrloginappgooglename: 'Google',

    wapplrloginappemail: 'Email',
    wapplrloginapppassword: 'Jelszó',
    wapplrloginappnewpassword: 'Új jelszó',
    wapplrloginappconfirmpassword: 'Jelszó megerősítés',
    wapplrloginapploginsubmit: 'Belépés',
    wapplrloginapplastname: 'Vezetéknév',
    wapplrloginappfirstname: 'Keresztnév',
    wapplrloginappsignupsubmit: 'Regisztráció',
    wapplrloginappforgotsubmit: 'Jóváhagyó email küldése',
    wapplrloginappavatarsubmit: 'Új profilkép mentése',
    wapplrloginappresetsubmit: 'Új jelszó mentése',
    wapplrloginappchangeemailsubmit: 'Email cím mentése',
    wapplrloginappconfirmsendsubmit: 'Jóváhagyó email újraküldése',
    wapplrloginappbasicsubmit: 'Mentés',

    wapplrloginappconfirmsendname: 'Email megerősítése',
    wapplrloginappconfirmsendtitle: 'Megerősítő email küldése',

    wapplrloginappconfirmationtitle: 'Email megerősítése',
    wapplrloginappconfirmationisvalidated: 'Email cím meg van erősítve',
    wapplrloginappconfirmationmissingkeytext: 'Hiányzó kulcs',
    wapplrloginappconfirmationprocessingtext: 'Email megerősítése...',
    wapplrloginappconfirmationloggedouttext: 'Belépés szükséges mielőtt az email címét érvényesítené',
    wapplrloginappconfirmsendloggedouttext: 'Belépés szükséges mielőtt elküldené a megerősítő emailt az email címe érvényesítéséhez',

    wapplrloginappmincharerror: function(a) {
        return 'Minimum ' + a + ' karakter'
    },
    wapplrloginappemailerror: 'Kérlek addj meg egy valós email címet',
    wapplrloginappspecialwordserror: 'Kérlek csak betüket használj',
    wapplrloginapppasswordequalerror: 'A Jelszó megerősítés nem egyezik a jelszóval',

    wapplrloginappconfirmationsubtitle: 'Email cím megerősítése',
    wapplrloginapploginsubtitle: 'Belépés email címmel',
    wapplrloginappconfirmsendsubtitle: 'Megerősítő email küldése az email cím érvényesítéséhez',
    wapplrloginappforgotsubtitle: 'Megerősítő email küldése új jelszó beállításához',
    wapplrloginappresetsubtitle: 'Új jelszó beállítása',
    wapplrloginappsignupsubtitle: 'Regisztráció email címmel',

    wapplrloginappresetmissingkeytext: 'Hiányzó kulcs',

    wapplrloginapploginheadertitle: 'Belépés',
    wapplrloginappsignupheadertitle: 'Regisztráció',
    wapplrloginapplogoutheadertitle: 'Fiók',
    wapplrloginappconfirmheadertitle: 'Email megerősítése',
    wapplrloginappforgotheadertitle: 'Elfelejtett jelszó',
    wapplrloginappresetheadertitle: 'Új jelszó',

    wapplrloginapplogoutbuttontext: 'Kilépés',

    wapplrloginappchangepasssubtitle: 'Új jelszó beállítása',
    wapplrloginappchangepassloggedouttext: 'Belépés szükséges a jelszó változtatásához',
    wapplrloginappchangeemailsubtitle: 'Új email cím beállítása',
    wapplrloginappchangeemailloggedouttext: 'Belépés szükséges az email változtatásához',
    wapplrloginappchangebasicsubtitle: 'Alap adatok beállítása',
    wapplrloginappchangebasicloggedouttext: 'Belépés szükséges az adatok változtatásához',
    wapplrloginappchangepassheadertitle: 'Új jelszó',
    wapplrloginappchangeemailheadertitle: 'Új email cím',
    wapplrloginappchangebasicheadertitle: 'Alap adatok',
    wapplrloginappchangeavatarsubtitle: 'Új profilkép beállítása',
    wapplrloginappsocialsubtitle: 'Belépés más szolgáltatásokkal',

    wapplrloginappchangepasswordtitle: 'Jelszó megváltoztatása',
    wapplrloginappchangeemailwordtitle: 'Email cím megváltoztatása',
    wapplrloginappchangebasicwordtitle: 'Alap adatok megváltoztatása',

    wapplrloginappchangepassname: 'Jelszó megváltoztatása',
    wapplrloginappchangeemailname: 'Email megváltoztatása',
    wapplrloginappchangeavatarname: 'Profilkép megváltoztatása',
    wapplrloginappchangebasicname: 'Név megváltoztatása',

    wapplruserappsettingsname: 'Beállítások',
    wapplruserappsettingstitle: 'Beállítások',
    wapplruserappprofilename: 'Profil',
    wapplruserappdahsboardname: 'Irányítópult',
    accountsettings: 'Beállítások',

    signfail: 'Hiba történt a beléptetés közben, próbálkozzon újra',
    emailfail: 'Hiba történt az email küldés közben, próbálkozzon újra',
    incorrectpassword: 'Hibás jelszó',
    incorrectemail: 'Hibás email',
    incorrectemailfacebook: 'Ha a facebook email szolgáltatását használja, lépjen be facebook fiókjával',

    forgotfail: 'Hiba történt az email küldése közben, próbálkozzon újra',
    forgotsuccess: 'Az email el lett küldve a megadott címre',

    resetfail: 'Hiba történt az új jelszó mentés közben közben, próbálkozzon újra',
    resetsuccess: 'Az új jelszó elmentve',
    incorrectresetdata: 'Hibás vagy lejárt kulcs vagy email',

    confirmsendfail: 'Hiba történt az email küldése közben, próbálkozzon újra',
    confirmsendsuccess: 'Az email el lett küldve a megadott címre',

    confirmationfail: 'Hiba történt az email cím megerősítése közben, próbálkozzon újra',
    confirmationsuccess: 'Email cím megerősítve',
    confirmationkeyfail: 'Hibás kulcs, próbálja újra',

    saveemailfail: 'Hiba történt az email cím mentése közben, próbálkozzon újra',
    saveemailsuccess: 'Az új email cím elmentve',

    basicdatafail: 'Hiba történt az adatok mentése közben, próbálkozzon újra',
    basicdatasuccess: 'Az új adatok elmentve',

    saveavatarfail: 'Hiba történt a profilkép mentése közben, próbálkozzon újra',
    saveavatarsuccess: 'Az új profilkép elmentve',

    saveusertypefail: 'Hiba történt a regisztráció típus mentése közben, próbálkozzon újra',
    saveusertypesuccess: 'A regisztráció típusa elmentve',

    deleteaccountfail: 'Hiba történt a fiók törlése közben, próbálkozzon újra',
    deleteaccountsuccess: 'A fiók törölve',

    usedemail: 'Ez ez email cím már használatban van',
    nochange: 'Nem történt változás',

    wapplruserappprofiletitle: 'Profil',

    wapplrloginappdeleteaccountsubtitle: 'Fiók törlése (a fiók 30 napig visszaállítható, ha a felhasználó belép a rendszerbe)',
    wapplrloginappdeleteaccountloggedouttext: 'Belépés szükséges a fiók törléséhez',
    wapplrloginappdeleteaccountheadertitle: 'Törlés',
    wapplrloginappdeleteaccounttitle: 'Fiók törlése',
    wapplrloginappdeleteaccountsubmit: 'Törlés',
    wapplrloginappdeleteaccountname: 'Fiók törlése',

    userstatusregistrated: 'regisztrált',
    userstatusdeleteditself: 'törölve felhasználó által',
    userstatusbanned: 'törölve véglegesen',
    userstatusrequireddata1: 'kötelező adatok hiányoznak & email megerősítve',
    userstatusrequireddata2: 'kötelező adatok megadva & email nincs megerősítve',
    userstatusrequireddata3: 'minden adat rendelkezésre áll',
    userstatusapproves: 'felhasználó jóváhagyva',

    stepuserbasic: 'Alap adatok',
    stepuseravatar: 'Profilkép',

    deleteusersubtitle: 'Felhasználó törlése',
    deleteuserquestion: 'Biztos benne, hogy törölni szeretné a felhasználót (mintha magát törölte volna)',

    facebookmissingemail: 'Ez a szolgáltatás (Facebook) nem küldött vissza email címet, és a mi rendszerünkbe való belépéshez ez szükséges. Kérjük adja meg az email címét regisztrációhoz!',

    usersearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Keresés a felhasználók között'
        if (username && !taxs) r = 'Keresés ' + username + ' felhasználói között'
        if (!username && taxs) r = 'Keresés a felhasználók között ' + taxs + ' kategóriában'
        if (username && taxs) r = 'Keresés ' + username + ' felhasználói között, a ' + taxs + ' kategóriában'
        return r
    },

    metaregistrateddate: 'Regisztráció dátuma',

    approveterms: 'Approve Terms and Conditions',

    approveterms1: 'I read and I agree the content of the ',
    approveterms2: 'Terms and Conditions',
    approveterms3: ' and the ',
    approveterms4: 'Privacy',
    approveterms5: '',
    approveterms6: 'Newsletter: I consent to the processing of my personal data for the purpose of sending newsletters.',
    approvetermsreq: 'Required',

}
