import React from 'react'
import createReactClass from 'create-react-class'

import TemplateSettingsbox from '../templates/template_settingsbox'
import Formsy from '../components/Formsy'

export default createReactClass({
    getConstruct: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct : { props: { state: teststate } }
    },
    render: function() {



        const {
            formsystyle,
            changebasicdata,
            changebasicsubmit,
            changebasicsubtitle = 'Set your basic data'

        } = this.props

        const construct = this.getConstruct()

        return (
            <TemplateSettingsbox {...this.props} title={changebasicsubtitle} slug='changebasic'>
                <Formsy formdata={changebasicdata} submitForm={changebasicsubmit} style={formsystyle}
                        construct={construct} />
            </TemplateSettingsbox>
        )
    }
})
