import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'
import s from '../empty.css'
import StarIcon from 'material-ui/svg-icons/toggle/star'

const Badge = createReactClass({
    getInitialState: function() {
        return {
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    render() {
        const { style, Icon = StarIcon, value, size, fontsize } = this.props
        return (
            <div className={style.badge} style={(size) ? { width: size, height: size } : null}>
                <div className={style.icon}><Icon /></div>
                {(value) ?
                    <div className={style.value}>
                        <div className={style.valuein} style={(fontsize) ? { fontSize: fontsize } : null}>{value}
                        </div>
                    </div> : null
                }
            </div>
        )
    }

})

const Middle = createReactClass({
    render: function() {
        const { setRef } = this.props
        return (
            <Badge {...this.props} ref={(setRef) ? function(e) {
                setRef('badge', e)
            } : null} />
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props
        const R = (style && !disableInitWithStyles) ? withStyles(style)(Middle) : Middle
        const input = { ...this.props, style }
        return (
            <R {...input} />
        )
    }
})
