import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import CircularProgress from 'material-ui/CircularProgress'

import { decode } from 'querystring'
import store from 'store'

export default createReactClass({
    getInitialState() {
        return {
            isMounted: false,
            processing: false,
            disablerunneverever: false,
            key: null,
            error: null
        }
    },
    processingStart() {
        if (this.state.isMounted) {
            this.setState({
                processing: true
            })
        }
    },
    processingEnd() {
        if (this.state.isMounted) {
            this.setState({
                processing: false,
                disablerunneverever: true
            })
        }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getKey: function() {
        const state = this.getGlobalState()
        if (state && state.query && state.query.confirmemailkey) return state.query.confirmemailkey

        const { history } = this.props
        let search = (history && history.location) ? history.location.search : ''
        if (search.slice(0, 1) === '?') search = search.slice(1)
        const so = (search) ? decode(search) : {}
        return so.confirmemailkey
    },
    invalidateForm: function(p) {
        if (p) {
            const errortextfield = Object.keys(p)[0]
            const errortextmessage = p[errortextfield]
            if (errortextmessage && this.state && this.state.isMounted) {
                this.setState({
                    error: errortextmessage
                })
            }
        }
    },
    componentDidUpdate: function(prevProps, prevState) {
        const prevKey = prevState.key
        const key = this.state.key


        if (key !== prevKey) {
            this.run()
        }
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    getInitialNow: function() {
        const state = this.getGlobalState()
        return (state && state.runtime && state.runtime.initialNow) ? state.runtime.initialNow : null
    },
    run: function() {

        const { confirmationsubmit } = this.props
        const me = this.getMe()
        const key = this.state.key
        const invalidateForm = this.invalidateForm
        const initialNow = this.getInitialNow()
        const keys = store.get('wapplrlogin_emailconfirm') || []

        let found = null
        keys.map(function(k) {
            if (k.key === key && k.id === me.id && k.initialNow === initialNow) found = true
        })

        if (!found && this.state && this.state.isMounted && !this.state.processing && !this.state.disablerunneverever && key && confirmationsubmit && me && me.id && !me.emailisvalidated) {
            const data = {
                confirmemailkey: key
            }
            const refs = {
                processingStart: this.processingStart,
                processingEnd: this.processingEnd
            }

            const addtokeys = { key: key, id: me.id, initialNow: initialNow }
            const newkeys = [addtokeys]
            keys.map(function(k) {
                if (k && k.initialNow !== initialNow) newkeys.push(k)
            })
            store.set('wapplrlogin_emailconfirm', newkeys)
            confirmationsubmit(data, null, invalidateForm, refs)
        }
    },
    componentDidMount: function() {

        const key = this.getKey()

        this.setState({
            isMounted: true,
            key: key
        })

    },
    render: function() {

        const {
            style,
            confirmationsubtitle = 'Confirmation your email',
            confirmationisvalidated = 'Your email address was validated',
            confirmationmissingkeytext = 'Missing key',
            confirmationprocessingtext = 'Validating your email...',
            confirmationloggedouttext = 'Please log in before you trying email validation'
        } = this.props

        const me = this.getMe()
        const processing = this.state.processing

        return (
            <TemplateDefault {...this.props} title={confirmationsubtitle} slug='confirm'>
                {(!me || me && !me.id) ?
                    <div className={style.centerinfo}>
                        {confirmationloggedouttext}
                    </div> :
                    (me && me.id && !me.emailisvalidated) ?
                        !(this.state.key) ?
                            (this.state.isMounted) ?
                                <div className={style.centerinfo}>{confirmationmissingkeytext}</div> : null
                            :
                            <div>
                                {(processing) ?
                                    <div className={style.processingcontainer}>
                                        <CircularProgress className={style.processingicon} size={24} thickness={3} />
                                        <div className={style.centerinfo + ' ' + style.processingtext}>
                                            {confirmationprocessingtext}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        :
                        <div className={style.centerinfo}>
                            {confirmationisvalidated}
                        </div>
                }
                {(this.state.error) ?
                    <div className={style.centerinfo + ' ' + style.errortext}>
                        {this.state.error}
                    </div> :
                    null
                }
            </TemplateDefault>
        )
    }
})
