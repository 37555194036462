import React from 'react'
import createReactClass from 'create-react-class'
import wapplrcomponents from '../../../../components'
import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import fetchs from '../../../services/fetchs'

import Wapplrdialog from '../../../components/Wapplrdialog'
import wds from "../../../components/Wapplrdialog/Wapplrdialog.css";
import helpers from '../../../services/requests/helpers'
import Formsy from '../../../components/Formsy'
import Toggle from '../../../components/Toggle/formsy'
import tos from '../../../components/Toggle/Toggle.css'

const Wapplr = wapplrcomponents.wapplr

const Parent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            isMounted: false
        }
    },
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },
    approveTerms: async function({ callback }) {

        const { curlang = {}, termspostid, privancyid } = this.props
        const terms = this.refElements['dialog']
        const props = this.props

        const {
            approveterms = 'Approve terms and conditions',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        if (terms && terms.dialogOpen) {

            const postQuery = helpers.post()

            const postRequest = {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: function({ id }) {
                    const reqName = 'current' + 'post'
                    return JSON.stringify({
                        query: `{
                                    ${reqName}(id:"${id}") {
                                        ${postQuery}
                                    }
                                }`
                    })
                },
                credentials: 'include'
            }

            const pRbody = postRequest.body({ id: termspostid })
            const fresp = await fetch('/graphql', { ...postRequest, body: pRbody })
            let response = (fresp && fresp.json) ? await fresp.json() : null
            if (response.data) response = response.data

            const termsPostData = {}
            const formsyRefs = {}

            const action = function(e) {
                formsyRefs.formsy.submit(e)
            }

            terms.dialogOpen({
                title: approveterms,
                text: '',
                content: createReactClass({
                    render: function() {

                        const { dialogRefElements } = this.props

                        return (
                            <div>
                                <Formsy
                                    setRef={function(a, e) {
                                        formsyRefs[a] = e
                                    }}
                                    onEnableButton={function() {
                                        dialogRefElements.submitbutton.setDisabled(false)
                                    }}
                                    onDisableButton={function() {
                                        dialogRefElements.submitbutton.setDisabled(true)
                                    }}
                                    submitForm={function() {
                                        terms.dialogClose()
                                        callback()
                                    }}
                                    formdata={
                                        [
                                            {
                                                type: 'toggle',
                                                component: Toggle,
                                                attr: {
                                                    name: 'approveterms',
                                                    label: <div>
                                                        <span>{curlang.approveterms1}</span>
                                                        <span><a href={'/post/' + termspostid} target={'_blank'}
                                                                 style={{ fontWeight: 'bold' }}>{curlang.approveterms2}</a></span>
                                                        <span>{curlang.approveterms3}</span>
                                                        <span><a href={'/post/' + privancyid} target={'_blank'}
                                                                 style={{ fontWeight: 'bold' }}>{curlang.approveterms4}</a></span>
                                                        <span>{curlang.approveterms5}</span>
                                                    </div>,
                                                    style: function() {
                                                        return tos
                                                    },
                                                    validations: {
                                                        req: function(values, value) {
                                                            return value === true

                                                        }
                                                    },
                                                    validationErrors: {
                                                        req: curlang.approvetermsreq
                                                    },
                                                    required: true,
                                                    onChange: function() {
                                                        return function(e, value) {
                                                            termsPostData['approveterms'] = !!(value)
                                                        }
                                                    },
                                                    defaultToggled: function() {
                                                        return (termsPostData['approveterms'] === true)
                                                    }
                                                }
                                            }
                                            /*{
                                                type: "toggle",
                                                component: Toggle,
                                                attr: {
                                                    name: "approvenewsletter",
                                                    label: curlang.approveterms6,
                                                    style: function(state, props) {
                                                        return tos
                                                    },
                                                    validations:{
                                                        req: function(values, value) {
                                                            if (value == true) return true;
                                                            return false;
                                                        }
                                                    },
                                                    validationErrors: {
                                                        req: curlang.approvetermsreq
                                                    },
                                                    required: true,
                                                    onChange: function(state, props) {
                                                        return function(e, value) {
                                                            const {post} = props;
                                                            termsPostData["approvenewsletter"] = (value) ? true : false;
                                                        }
                                                    },
                                                    defaultToggled: function(state, props) {
                                                        const {post} = props;
                                                        return (termsPostData["approvenewsletter"] == true) ? true : false
                                                    },
                                                }
                                            },*/
                                        ]
                                    } />
                            </div>
                        )
                    }
                }),
                contentprops: {
                    ...props,
                    Parent: Parent,
                    response: response
                },
                submittext: submittext,
                canceltext: canceltext,
                action: action
            })
        } else {
            callback()
        }
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getLink: function(type, p) {

        const { routes } = this.props

        const {
            menuuserslug = '/user',
            menuloginslug = '/signin',
            wapplrloginappconfirmationslug = '/confirmation',
            wapplrloginappconfirmsendslug = '/confirmation/send'
        } = routes


        if (type === 'user' && p.id) return { pathname: menuuserslug + '/' + p.id }
        if (type === 'signin') return { pathname: menuloginslug }
        if (type === 'confirmation') return { pathname: menuloginslug + wapplrloginappconfirmationslug }
        if (type === 'confirmsend') return { pathname: menuloginslug + wapplrloginappconfirmsendslug }

        return { pathname: menuloginslug }

    },
    componentDidMount: function() {

        const { wapplrloginfunctions, curlang, history, updatefunctions } = this.props

        const {

            forgotfail = 'Sorry, there was an issue sending email to you, please try again',
            forgotsuccess = 'The confirm email has been sent to your email address',

            confirmationfail = 'Sorry, there was an issue validating your email, please try again',
            confirmationsuccess = 'Your email address was confirmed',

            confirmsendfail = 'Sorry, there was an issue sending email to you, please try again',
            confirmsendsuccess = 'The confirm email has been sent to your email address',

            resetfail = 'Sorry, there was an issue saving new password, please try again',
            resetsuccess = 'The new password has been saved',

            saveemailfail = 'Sorry, there was an issue saving email, please try again',
            saveemailsuccess = 'The new email has been saved',

            basicdatafail = 'Sorry, there was an issue saving data, please try again',
            basicdatasuccess = 'The new data has been saved',

            deleteaccountfail = 'Sorry, there was an issue delete your acocunt, please try again',
            deleteaccountsuccess = 'Your account has been deleted'

        } = curlang

        const getLink = this.getLink
        const historyPush = this.historyPush
        const snack = this.snack
        const getHelpers = this.getHelpers
        const t = this;

        wapplrloginfunctions.forgot = fetchs.forgot({
            getHelpers: getHelpers,
            history: history,
            replacefromstate: { me: { email: 1 } },
            success: function() {
                snack(forgotsuccess)
                setTimeout(function() {
                    historyPush(getLink('signin'))
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                const field = response.field || ''
                const message = response.message || forgotfail
                if (field === 'snack') {
                    if (message) snack(message)
                } else {
                    if (field && message) {
                        invalidateForm({ [field]: message })
                    }
                }
            }
        })

        wapplrloginfunctions.confirmation = fetchs.confirmation({
            getHelpers: getHelpers,
            history: history,
            success: function({ response }) {
                snack(confirmationsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(getLink('user', { id: response.userId }))
                            }
                        })
                    } else {
                        window.location.href = '/user/' + response.userId
                    }
                }, 1500)
            },

            fail: function({ response, invalidateForm }) {
                let field = response.field || ''
                const message = response.message || confirmationfail
                if (field === 'snack') {
                    if (message) snack(message)
                } else {
                    if (field && message) {
                        invalidateForm({ [field]: message })
                    }
                }
                setTimeout(function() {
                    historyPush(getLink('confirmsend'))
                }, 1500)
            }
        })

        wapplrloginfunctions.confirmsend = fetchs.confirmsend({
            getHelpers: getHelpers,
            history: history,
            replacefromstate: { me: { email: 1 } },
            success: function({ response }) {
                snack(confirmsendsuccess)
                setTimeout(function() {
                    historyPush(getLink('user', { id: response.userId }))
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                let field = response.field || ''
                const message = response.message || confirmsendfail
                if (field === 'snack') {
                    if (message) snack(message)
                } else {
                    if (field && message) {
                        invalidateForm({ [field]: message })
                    }
                }
            }
        })

        wapplrloginfunctions.reset = fetchs.reset({
            getHelpers: getHelpers,
            history: history,
            replacefromstate: { me: { email: 1 } },
            success: function({ response }) {
                snack(resetsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush('/user/' + response.userId)
                            }
                        })
                    } else {
                        window.location.href = '/user/' + response.userId
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                const field = response.field || ''
                const message = response.message || resetfail
                if (field && message) {
                    invalidateForm({ [field]: message })
                }
            }
        })

        wapplrloginfunctions.logout = fetchs.logout({
            getHelpers: getHelpers,
            history: history,
            success: function() {
                setTimeout(function() {
                    window.location.reload()
                }, 300)
            },
            fail: function() {
            }
        })

        wapplrloginfunctions.login = fetchs.login({
            getHelpers: getHelpers,
            history: history,
            success: function({ response }) {
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                const { callback } = t.state
                                let url = '/user/' + response.userId
                                if (callback) url = callback
                                historyPush(url)
                            }
                        })
                    } else {
                        const { callback } = t.state
                        let url = '/user/' + response.userId
                        if (callback) url = callback
                        window.location.href = url
                    }
                }, 300)
            },
            fail: function({ response, invalidateForm }) {
                const field = response.field || ''
                const message = response.message || 'Sorry, there was an issue signing you in, please try again.'
                if (field && message) {
                    invalidateForm({ [field]: message })
                }
            }
        })

        const approveTerms = this.approveTerms

        wapplrloginfunctions.signup = function(a, b, c, d) {
            approveTerms({
                callback: function() {
                    fetchs.signup({
                        getHelpers: getHelpers,
                        history: history,
                        success: function({ response }) {
                            setTimeout(function() {
                                if (updatefunctions) {
                                    updatefunctions.me({
                                        callback: function() {
                                            const { callback } = t.state
                                            let url = '/user/' + response.userId
                                            if (callback) url = callback
                                            historyPush(url)
                                        }
                                    })
                                } else {
                                    const { callback } = t.state
                                    let url = '/user/' + response.userId
                                    if (callback) url = callback
                                    window.location.href = url
                                }
                            }, 300)
                        },
                        fail: function({ response, invalidateForm }) {
                            if (response.messages) {
                                invalidateForm(response.messages)
                            } else {
                                const field = response.field || 'repeated_password'
                                const message = response.message || 'Sorry, there was an issue signing you in, please try again.'
                                if (field && message) invalidateForm({ [field]: message })
                            }
                        }
                    })(a, b, c, d)
                }
            })
        }

        wapplrloginfunctions.changepass = fetchs.changepass({
            getHelpers: getHelpers,
            history: history,
            success: function() {
                snack(resetsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(window.location.pathname)
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'repeated_password'
                    const message = response.message || curlang.resetDefaultFail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplrloginfunctions.changeemail = fetchs.changeemail({
            getHelpers: getHelpers,
            history: history,
            success: function() {
                snack(saveemailsuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(window.location.pathname)
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'email'
                    const message = response.message || saveemailfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplrloginfunctions.changebasic = fetchs.changebasic({
            getHelpers: getHelpers,
            history: history,
            success: function() {
                snack(basicdatasuccess)
                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(window.location.pathname)
                            }
                        })
                    } else {
                        window.location.reload()
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'lastname'
                    const message = response.message || basicdatafail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        wapplrloginfunctions.deleteaccount = fetchs.deleteaccount({
            getHelpers: getHelpers,
            history: history,
            success: function() {
                snack(deleteaccountsuccess)
                setTimeout(function() {
                    window.location.href = '/'
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'password'
                    const message = response.message || deleteaccountfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        const helpers = this.getHelpers() || {}
        const storedState = helpers.state
        const callback = (storedState && storedState.query && storedState.query.callback) ? decodeURIComponent(storedState.query.callback) : null

        const me = this.getMe()
        if (me && me.id && callback && typeof window !== 'undefined' && window.location.pathname === 'login' ||
            me && me.id && callback && typeof window !== 'undefined' && window.location.pathname === 'signup') {
            const t = this
            setTimeout(function() {
                t.historyPush(callback)
            }, 300)
        }

        const globalState = this.getGlobalState()
        if (typeof globalState.query.logout !== 'undefined' && window.location.search && window.location.search.match('logout=')) {
            window.history.pushState({}, '', window.location.pathname)
            const me = this.getMe()
            if (me && me.id) {
                setTimeout(function() {
                    wapplrloginfunctions.logout()
                }, 100)
            }
        }

        this.setState({ isMounted: true, callback: callback })

    },
    componentWillUnmount: function() {
        this.setState({ isMounted: false })
    },
    render: function() {

        const {
            style,
            wapplrloginapp,
            curlang,
            helpers,
            termspostid,
            privancyid,
            updatefunctions,
            history,
            routes,
            defaultNotificationSettings
        } = this.props
        const { state } = this.getHelpers()
        const setRef = this.setRef

        const opminput = {
            className: this.props.className,
            bodyClassName: this.props.bodyClassName,
            modal: this.props.modal,
            setRef: setRef,
            autoScrollBodyContent: true,
            renderTo: (typeof document !== 'undefined') ? document.body : null,
            style: wds
        }

        const globalState = this.getGlobalState()
        const me = this.getMe()
        if (me && me.id && typeof globalState.query.logout !== 'undefined') {
            return <TemplateDefault {...this.props} />
        }

        return (
            <TemplateDefault {...this.props}>
                <div className={style['col-md-3']} />
                <div className={style['col-md-6']}>
                    <div>
                        <Wapplr app={wapplrloginapp} state={state} curlang={curlang} helpers={helpers}
                                termspostid={termspostid}
                                privancyid={privancyid}
                                history={history}
                                updatefunctions={updatefunctions}
                                routes={routes}
                                defaultNotificationSettings={defaultNotificationSettings}
                        />
                    </div>
                </div>
                <div className={style['col-md-3']} />
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog {...opminput} />
            </TemplateDefault>
        )
    }
})
