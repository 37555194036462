import React from 'react'

import usergalleryhelpers from '../../../components/Usergallery/helpers'
import fetchs from '../../../services/fetchs'

export function editdata({ curlang, history, searchData, posttypesData, routes, uploadconfig, getthumb}) {

    return function({ user }) {
        return {
            0: [
                {
                    type: 'text',
                    attr: {
                        name: 'lastname',
                        hintText: curlang.wapplrloginapplastname,
                        floatingLabelText: curlang.wapplrloginapplastname,
                        validations: 'isSpecialWords',
                        validationError: curlang.wapplrloginappspecialwordserror,
                        style: { width: '100%' },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                if (!post.name) post.name = {}
                                post.name.last = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.name && post.name.last) ? post.name.last : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'firstname',
                        hintText: curlang.wapplrloginappfirstname,
                        floatingLabelText: curlang.wapplrloginappfirstname,
                        validations: 'isSpecialWords',
                        validationError: curlang.wapplrloginappspecialwordserror,
                        style: { width: '100%' },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                if (!post.name) post.name = {}
                                post.name.first = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.name && post.name.first) ? post.name.first : ''
                        }
                    }
                },
                {
                    type: 'text',
                    attr: {
                        name: 'email',
                        hintText: curlang.wapplrloginappemail,
                        floatingLabelText: curlang.wapplrloginappemail,
                        validations: 'isEmail',
                        validationError: curlang.wapplrloginappemailerror,
                        style: { width: '100%' },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post.email = value
                            }
                        },
                        required: true,
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.email) ? post.email : ''
                        }
                    }
                },
                {
                    type: 'toggle',
                    attr: {
                        name: 'accesstoplayer',
                        label: curlang.editlabelaccesstoplayer,
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        value: function(state, props) {
                            const { post } = props
                            return !!(post['accesstoplayer'])
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post['accesstoplayer'] = !!(value)
                            }
                        },
                        defaultToggled: function(state, props) {
                            const { post } = props
                            return !!(post && post['accesstoplayer'])
                        }
                    }
                },
                {
                    type: 'toggle',
                    attr: {
                        name: 'runscript',
                        label: curlang.editlabelrunscript,
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        value: function(state, props) {
                            const { post } = props
                            return !!(post['runscript'])
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post['runscript'] = !!(value)
                            }
                        },
                        defaultToggled: function(state, props) {
                            const { post } = props
                            return !!(post && post['runscript'])
                        }
                    }
                },
                {
                    type: 'toggle',
                    attr: {
                        name: 'disablescriptnotification',
                        label: curlang.editlabeldisablescriptnotification,
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { display: 'none', width: '100%' }
                            return { width: '100%' }
                        },
                        value: function(state, props) {
                            const { post } = props
                            return !!(post['disablescriptnotification'])
                        },
                        onChange: function(state, props) {
                            return function(e, value) {
                                const { post } = props
                                post['disablescriptnotification'] = !!(value)
                            }
                        },
                        defaultToggled: function(state, props) {
                            const { post } = props
                            return !!(post && post['disablescriptnotification'])
                        }
                    }
                },
                {
                    type: 'button',
                    attr: {
                        type: 'submit',
                        name: 'submit',
                        label: function() {

                            return curlang.next || 'next'
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { marginTop: 0 }
                            return { marginTop: 32 }
                        },
                        disabled: function(state = {}) {

                            return !state.canSubmit
                        }
                    }
                }
            ],
            1: [
                usergalleryhelpers({
                    fetchs, curlang, searchData, posttypesData, history, routes, uploadconfig, getthumb, config: {
                        editor: function(state, props) {
                            const { post } = props
                            return {
                                value: function() {
                                    let cp = {}
                                    if (post && post.avatarprops) {
                                        try {
                                            cp = JSON.parse(post.avatarprops)
                                        } catch (e) {
                                        }
                                    }
                                    return cp
                                },
                                onChange: function(p = {}) {
                                    const { value } = p
                                    const v = (typeof value == 'object') ? JSON.stringify(value) : value
                                    post.avatarprops = v
                                },
                                image: function() {
                                    if (post && post.avatarrel && typeof post.avatarrel == 'object' && post.avatarrel.cover) {
                                        return post.avatarrel.cover
                                    }
                                    if (post && post.avatar && typeof post.avatar == 'string') {
                                        return post.avatar
                                    }
                                    return ''
                                },
                                maskHeightPercent: 100,
                                showGrid: false
                            }
                        },
                        formname: 'gallery',
                        validations: {
                            maxLength: 1
                        },
                        validationErrors: {
                            maxLength: curlang.maxcharerror(1)
                        },
                        value: function(state, props) {
                            const { post } = props
                            return (post && post.avatarrel) ? [post.avatarrel] : []
                        },
                        getSelectedPosts: function({ post }) {
                            return (post && post.avatarrel) ? [post.avatarrel] : []
                        },
                        afterSelect: function({ e, selectedposts, setPosts, onChange, post }) {
                            const avatar = (selectedposts && selectedposts.length) ? selectedposts[selectedposts.length - 1] : null
                            if (avatar) {
                                post.avatarrel = avatar
                                post.avatar = avatar.cover
                                setPosts({ posts: [avatar], modalclose: true })
                                if (onChange) onChange(e, [avatar])
                            } else {
                                post.avatarrel = null
                                post.avatar = null
                                setPosts({ posts: [], modalclose: true })
                                if (onChange) onChange(e, [])
                            }
                        },
                        afterUpload: function({ response, setPosts, onChange, post}) {

                            let avatar = null

                            if (response) {
                                if (response.length) {
                                    let wassucc = false
                                    response.map(function(i) {
                                        if (i.success) {
                                            avatar = i
                                            if (i.post) avatar = i.post
                                            wassucc = true
                                        }
                                    })
                                } else {
                                    if (response.success) {
                                        avatar = response
                                        if (response.post) avatar = response.post
                                    }
                                }
                            }

                            if (avatar) {
                                post.avatarrel = avatar
                                post.avatar = avatar.cover
                                setPosts({ posts: [avatar], modalclose: true })
                                if (onChange) onChange(null, [avatar])
                            }

                        },
                        deleteFunction: function({ newposts, post }) {
                            const avatar = (newposts && newposts.length) ? newposts[newposts.length - 1] : null
                            if (avatar) {
                                post.avatarrel = avatar
                                post.avatar = avatar.cover
                            } else {
                                post.avatarrel = null
                                post.avatar = null
                            }
                        },
                        selectsimple: true,
                        title: 'Avatar',
                        data: {
                            userId: user && user.id
                        }
                    }
                }),
                {
                    type: 'button',
                    attr: {
                        type: 'submit',
                        name: 'submit',
                        label: function(state, props) {
                            const { restoretext } = props
                            return (state.processing) ? curlang.savepost : (restoretext) ? restoretext : curlang.savepost
                        },
                        style: function(state, props) {
                            const { hiddencontent } = props
                            if (hiddencontent) return { marginTop: 0 }
                            return { marginTop: 32 }
                        },
                        disabled: function(state = {}) {

                            return !state.canSubmit
                        }
                    }
                }
            ]
        }
    }
}
