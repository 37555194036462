import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import Formsy from '../components/Formsy'

export default createReactClass({
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    render: function() {

        const { teststate } = this.props

        const {
            style,
            formsystyle,
            construct = { props: { state: teststate } },

            changebasicdata,
            changebasicsubmit,
            changebasicsubtitle = 'Set your basic data',
            changebasicloggedouttext = 'Please log in before you trying change your data'

        } = this.props

        const me = this.getMe()

        return (
            <TemplateDefault {...this.props} title={changebasicsubtitle} slug='changebasic'>
                {(me && me.id) ?
                    <Formsy formdata={changebasicdata} submitForm={changebasicsubmit} style={formsystyle}
                            construct={construct} /> :
                    <div className={style.centerinfo}>
                        {changebasicloggedouttext}
                    </div>
                }
            </TemplateDefault>
        )
    }
})
