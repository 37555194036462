import React from 'react'
import createReactClass from 'create-react-class'
import TemplateCustom from '../templates/template_custom'
import ContentChangeAvatar from '../../module_wapplruser/contents/content_change_avatar'
import NotFound from '../../../../../components/NotFound'
import nfs from '../../../../../components/NotFound/default.css'

const DefaultParentComponent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

export default createReactClass({
    getConstruct: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct : { props: { state: teststate } }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        const user = (state && state.response && state.response.user) ? state.response.user : null
        if (me && user && user.id === me.id) {
            return user
        }
        return me
    },
    render: function() {
        const { Parent = TemplateCustom, history } = this.props

        const construct = this.getConstruct()


        const { curlang = {}, routes = {}} = construct.props

        const {
            wapplrloginappchangeavatarname = 'Set your avatar image'
        } = curlang

        const me = this.getMe()

        return (
            <Parent {...this.props} title={wapplrloginappchangeavatarname}>
                {(me && me.id) ?
                    <ContentChangeAvatar {...this.props}
                                         Parent={DefaultParentComponent}
                                         user={{ ...me }}
                    />
                    :
                    <NotFound
                        style={nfs}
                        history={history}
                        notFoundText={curlang.notfoundtext}
                        notFoundDesc={curlang.notfounddesc}
                        loggedOutDesc={(me && me.id) ? null : curlang.loggedoutdesc}

                        backLabel={curlang.gotobacktoprev}
                        homeLabel={curlang.gotohomepage}
                        loginLabel={(me && me.id) ? null : curlang.gotologin}
                        signupLabel={(me && me.id) ? null : curlang.gotosignup}
                        profileLabel={(me && me.id) ? curlang.gotoprofile : null}

                        homeHref={'/'}
                        loginHref={(me && me.id) ? null : (typeof window !== 'undefined' && window && window.location && window.location.pathname) ? routes.menuloginslug + '?callback=' + window.location.pathname : routes.menuloginslug}
                        signupHref={(me && me.id) ? null : (typeof window !== 'undefined' && window && window.location && window.location.pathname) ? routes.menuloginslug + routes.wapplrloginappsignupslug + '?callback=' + window.location.pathname : routes.menuloginslug + routes.wapplrloginappsignupslug}
                        profileHref={(me && me.id) ? routes.menuuserslug + '/' + me.id : null}

                    />
                }
            </Parent>
        )
    }
})
