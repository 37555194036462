import React from 'react'
import createReactClass from 'create-react-class'
import TemplateCustom from '../templates/template_custom'
import RaisedButton from 'material-ui/RaisedButton'
import FacebookIcon from '../../../../../components/FacebookIcon'
import GoogleIcon from '../../../../../components/GoogleIcon'
import Formsy from '../../../../../components/Formsy'
import { decode } from 'querystring'

import Wapplrdialog from '../../../../../components/Wapplrdialog'
import wds from '../../../../../components/Wapplrdialog/Wapplrdialog.css'
import helpers from '../../../../../services/requests/helpers'

import Toggle from '../../../../../components/Toggle/formsy'
import tos from '../../../../../components/Toggle/Toggle.css'

const Parent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            error: this.getErrorFromSearch()
        }
    },
    getErrorFromSearch: function() {
        const { history } = this.props
        let search = (history && history.location) ? history.location.search : ''
        if (search.slice(0, 1) === '?') search = search.slice(1)
        const so = (search) ? decode(search) : {}
        return (so.error) ? so.error : ''
    },
    getConstruct: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct : { props: { state: teststate } }
    },
    getHelpers: function() {
        const { construct } = this.props
        const { helpers } = construct.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers && helpers.store) ? helpers.store : null
        const states = (helpers && helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    componentDidMount: function() {


    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    approveTerms: async function({ callback }) {

        const { construct = {}, enableapproveterms } = this.props
        const { curlang = {}, termspostid, privancyid } = construct.props

        const props = this.props
        const terms = this.refElements['dialog']

        const {
            approveterms = 'Approve terms and conditions',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        if (terms && terms.dialogOpen && enableapproveterms) {

            const postQuery = helpers.post()

            const postRequest = {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: function({ id }) {
                    const reqName = 'current' + 'post'
                    return JSON.stringify({
                        query: `{
                                    ${reqName}(id:"${id}") {
                                        ${postQuery}
                                    }
                                }`
                    })
                },
                credentials: 'include'
            }

            const pRbody = postRequest.body({ id: termspostid })
            const fresp = await fetch('/graphql', { ...postRequest, body: pRbody })
            let response = (fresp && fresp.json) ? await fresp.json() : null
            if (response.data) response = response.data

            const termsPostData = {}
            const formsyRefs = {}

            const action = function(e) {
                formsyRefs.formsy.submit(e)
            }

            terms.dialogOpen({
                title: approveterms,
                text: '',
                content: createReactClass({
                    render: function() {

                        const { dialogRefElements } = this.props

                        return (
                            <div>
                                <Formsy
                                    setRef={function(a, e) {
                                        formsyRefs[a] = e
                                    }}
                                    onEnableButton={function() {
                                        dialogRefElements.submitbutton.setDisabled(false)
                                    }}
                                    onDisableButton={function() {
                                        dialogRefElements.submitbutton.setDisabled(true)
                                    }}
                                    submitForm={function() {
                                        terms.dialogClose()
                                        callback()
                                    }}
                                    formdata={
                                        [
                                            {
                                                type: 'toggle',
                                                component: Toggle,
                                                attr: {
                                                    name: 'approveterms',
                                                    label: <div>
                                                        <span>{curlang.approveterms1}</span>
                                                        <span><a href={'/post/' + termspostid} target={'_blank'}
                                                                 style={{ fontWeight: 'bold' }}>{curlang.approveterms2}</a></span>
                                                        <span>{curlang.approveterms3}</span>
                                                        <span><a href={'/post/' + privancyid} target={'_blank'}
                                                                 style={{ fontWeight: 'bold' }}>{curlang.approveterms4}</a></span>
                                                        <span>{curlang.approveterms5}</span>
                                                    </div>,
                                                    style: function() {
                                                        return tos
                                                    },
                                                    validations: {
                                                        req: function(values, value) {
                                                            return value === true
                                                        }
                                                    },
                                                    validationErrors: {
                                                        req: curlang.approvetermsreq
                                                    },
                                                    required: true,
                                                    onChange: function() {
                                                        return function(e, value) {
                                                            termsPostData['approveterms'] = !!(value)
                                                        }
                                                    },
                                                    defaultToggled: function() {

                                                        return (termsPostData['approveterms'] === true)
                                                    }
                                                }
                                            }
                                            /*{
                                                type: "toggle",
                                                component: Toggle,
                                                attr: {
                                                    name: "approvenewsletter",
                                                    label: curlang.approveterms6,
                                                    style: function(state, props) {
                                                        return tos
                                                    },
                                                    validations:{
                                                        req: function(values, value) {
                                                            if (value == true) return true;
                                                            return false;
                                                        }
                                                    },
                                                    validationErrors: {
                                                        req: curlang.approvetermsreq
                                                    },
                                                    required: true,
                                                    onChange: function(state, props) {
                                                        return function(e, value) {
                                                            const {post} = props;
                                                            termsPostData["approvenewsletter"] = (value) ? true : false;
                                                        }
                                                    },
                                                    defaultToggled: function(state, props) {
                                                        const {post} = props;
                                                        return (termsPostData["approvenewsletter"] == true) ? true : false
                                                    },
                                                }
                                            },*/
                                        ]
                                    } />
                            </div>
                        )
                    }
                }),
                contentprops: {
                    ...props,
                    Parent: Parent,
                    response: response
                },
                submittext: submittext,
                canceltext: canceltext,
                action: action
            })
        } else {
            callback()
        }
    },
    click: function(e, d) {

        const { type } = d


        const approveTerms = this.approveTerms

        if (type === 'facebook') {
            approveTerms({
                callback: function() {
                    window.location.href = '/auth/facebook'
                }
            })
        }

        if (type === 'google') {
            approveTerms({
                callback: function() {
                    window.location.href = '/auth/google'
                }
            })
        }

        e.preventDefault()

    },
    render: function() {



        const {
            style,
            Parent = TemplateCustom
        } = this.props

        const setRef = this.setRef

        const construct = this.getConstruct()
        const state = this.state

        const { curlang = {} } = construct.props

        const {
            wapplrloginappsocialsubtitle = 'Login with another services'
        } = curlang

        const touchEnd = this.touchEnd

        const opminput = {
            ...this.props,
            setRef: setRef,
            style: wds,
            autoScrollBodyContent: true,
            renderTo: (typeof document !== 'undefined') ? document.body : null
        }

        return (
            <Parent {...this.props} title={wapplrloginappsocialsubtitle}>
                {(state.error && state.error === 'facebookmissingemail') ?
                    <div className={style.errortext} style={{ padding: '0 10px 20px' }}>
                        {(curlang[state.error]) ? curlang[state.error] : null}
                    </div> : null
                }
                <div className={style.socialtable}>
                    <div className={style.button}>
                        <RaisedButton
                            style={{ width: '100%' }}
                            label={'Facebook'}
                            icon={<FacebookIcon />}
                            onClick={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'facebook' })
                            }}
                            onTouchStart={function() {

                            }}
                            backgroundColor={'#3B5998'}
                            labelColor={'#ffffff'}
                        />
                    </div>
                    <div className={style.button}>
                        <RaisedButton
                            style={{ width: '100%' }}
                            label={'Google'}
                            icon={<GoogleIcon />}
                            onClick={function(e) {
                                touchEnd(e, 'click', 'click', { type: 'google' })
                            }}
                            onTouchStart={function() {

                            }}
                            backgroundColor={'#EA4335'}
                            labelColor={'#ffffff'}
                        />
                    </div>
                </div>
                <Wapplrdialog {...opminput} />
            </Parent>
        )
    }

})
