import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import Formsy from '../components/Formsy'

import Wapplruserbox from '../components/Wapplruserbox'

export default createReactClass({
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    render: function() {

        const {
            style,
            signupdata,
            signupsubmit,
            formsystyle,
            signupsubtitle = 'Signup with email',
            userboxstyle,
            avatarstyle,
            logoutsubmit,
            history,
            logoutbuttontext,
            getthumb,
            getthumbstyle,
            getavatarclassname,
            changeavatarhref,
            changeavatarhovertext
        } = this.props
        const me = this.getMe()
        const user = this.getUser()
        const className = (!me || me && !me.id) ? style['page-padding'] : 'emp'

        return (
            <TemplateDefault {...this.props} title={(!me || me && !me.id) ? signupsubtitle : null} className={className}
                             slug='signup'>
                {(!me || me && !me.id) ?
                    <Formsy formdata={signupdata} submitForm={signupsubmit} style={formsystyle} /> :
                    <Wapplruserbox style={userboxstyle}
                                   avatarstyle={avatarstyle}
                                   user={me}
                                   history={history}
                                   logoutsubmit={logoutsubmit}
                                   logoutbuttontext={logoutbuttontext}
                                   getthumb={getthumb}
                                   getthumbstyle={getthumbstyle}
                                   getavatarclassname={getavatarclassname}
                                   href={(me && user && me.id === user.id && changeavatarhref) ? changeavatarhref : null}
                                   changeavatarhovertext={(me && user && me.id === user.id && changeavatarhovertext) ? changeavatarhovertext : null}
                    />
                }
            </TemplateDefault>
        )
    }
})
