import React from 'react'
import createReactClass from 'create-react-class'

import TemplateSettingsbox from '../templates/template_settingsbox'
import Formsy from '../components/Formsy'

export default createReactClass({
    getInitialState() {
        return {
            isMounted: false,
            error: null
        }
    },
    componentDidMount() {
        this.setState({
            isMounted: true
        })
    },
    invalidateForm: function(p) {
        if (p) {
            const errortextfield = Object.keys(p)[0]
            const errortextmessage = p[errortextfield]
            if (errortextmessage && this.state && this.state.isMounted) {
                this.setState({
                    error: errortextmessage
                })
            }
        }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    render: function() {

        const {
            style,
            confirmsenddata,
            confirmsendsubmit,
            formsystyle,
            confirmsendsubtitle = 'Send confirmation email to validate your email address',
            confirmationisvalidated = 'Your email address was validated'
        } = this.props

        const me = this.getMe()
        const invalidateForm = this.invalidateForm

        return (
            <TemplateSettingsbox {...this.props} title={confirmsendsubtitle} slug='confirmsend'>
                {(me && me.id && !me.emailisvalidated) ?
                    <Formsy formdata={confirmsenddata} canSubmit={true} submitForm={function(a, b, c, d) {
                        return confirmsendsubmit(a, b, invalidateForm, d)
                    }} style={formsystyle} />
                    :
                    <div className={style.centerinfo}>
                        {confirmationisvalidated}
                    </div>}
                {(this.state.error) ?
                    <div className={style.centerinfo + ' ' + style.errortext}>
                        {this.state.error}
                    </div> :
                    null
                }
            </TemplateSettingsbox>
        )
    }
})
