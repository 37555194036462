import React from 'react'

/*Icons*/
import LoginIcon from 'material-ui/svg-icons/action/account-circle'
import UserIcon from 'material-ui/svg-icons/action/perm-identity'
import SignupIcon from 'material-ui/svg-icons/content/add'
import HelpIcon from 'material-ui/svg-icons/action/help'
import LockIcon from 'material-ui/svg-icons/action/lock-outline'
import ExitIcon from 'material-ui/svg-icons/action/exit-to-app'
import SettingsIcon from 'material-ui/svg-icons/action/settings'
import EmailIcon from 'material-ui/svg-icons/communication/email'
import EmailIcon1 from 'material-ui/svg-icons/communication/mail-outline'
import ListIcon from 'material-ui/svg-icons/action/list'
import ImageIcon from 'material-ui/svg-icons/image/image'
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever'
import OfferIcon from 'material-ui/svg-icons/maps/local-offer'
import DashboardIcon from 'material-ui/svg-icons/action/dashboard'
import NotificationsIcon from 'material-ui/svg-icons/social/notifications'

import { getMinUserStatusWhenAllAccess } from '../../../server/userstatus/statuses'

export function getmenus({
                             curlang,
                             routes
                         }) {

    const minuserstatus = getMinUserStatusWhenAllAccess() - 1

    return {
        wapplrloginapp: [
            {
                name: curlang.wapplrloginapploginname,
                link: routes.wapplrloginapploginslug,
                lefticon: <LoginIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (!user || user && !user.id)
                }
            },
            {
                name: curlang.wapplrloginappsignupname,
                link: routes.wapplrloginappsignupslug,
                lefticon: <SignupIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (!user || user && !user.id)
                }
            }/*,{
                name: curlang.wapplrloginappfacebookname,
                link: routes.wapplrloginappsocial,
                lefticon: <FacebookIcon />,
                justicon: true,
                role:function(props) {
                    const {sendprops = {}} = props;
                    const {user} = sendprops;
                    return (!user || user && !user.id)
                }
            },{
                name: curlang.wapplrloginappgooglename,
                link: routes.wapplrloginappsocial,
                lefticon: <GoogleIcon />,
                justicon: true,
                role:function(props) {
                    const {sendprops = {}} = props;
                    const {user} = sendprops;
                    return (!user || user && !user.id)
                }
            }*/
        ],
        wapplrloginapp_fixeddrawer: [
            {
                name: curlang.wapplrloginappchangeemailname,
                link: routes.wapplrloginappchangeemailslug,
                lefticon: <EmailIcon1 />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: curlang.wapplrloginappchangepassname,
                link: routes.wapplrloginappchangepassslug,
                lefticon: <LockIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: curlang.wapplrloginappchangebasicname,
                link: routes.wapplrloginappchangebasicslug,
                lefticon: <UserIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                divider: 1,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: curlang.wapplrloginappconfirmsendname,
                link: routes.wapplrloginappconfirmsendslug,
                lefticon: <EmailIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id && !user.emailisvalidated)
                }
            },
            {
                name: curlang.wapplrloginappforgotname,
                link: routes.wapplrloginappforgotslug,
                lefticon: <HelpIcon />
            },
            {
                name: curlang.wapplrloginappdeleteaccountname,
                link: routes.wapplrloginappdeleteaccountslug,
                lefticon: <DeleteIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                divider: 1,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: curlang.wapplrloginapplogoutname, link: '/', lefticon: <ExitIcon />,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            }
        ],
        wapplruserapp: [
            {
                name: curlang.wapplruserappdahsboardname,
                link: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id) ? '/' + user.id : '/'
                },
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id && user.status > minuserstatus || me && me.isAdmin || me && me.isEditor)
                },
                lefticon: <DashboardIcon />,
                items: [
                    {
                        name: curlang.wapplruserapppostsname,
                        link: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id) ? routes.wapplruserapppostsslug + '/' + user.id : routes.wapplruserapppostsslug
                        },
                        role: function(props) {
                            const { sendprops = {} } = props
                            const { user} = sendprops
                            return (user && user.id && user.isAdmin || user && user.id && user.isEditor)
                        },
                        lefticon: <ListIcon />,
                        items: [
                            {
                                name: curlang.wapplruserapppoststrash,
                                link: function(props) {
                                    const { sendprops = {} } = props
                                    const { user } = sendprops
                                    return (user && user.id) ? routes.wapplruserapppostsslug + '/trash/' + user.id : routes.wapplruserapppostsslug
                                },
                                lefticon: <ListIcon />,
                                role: function(props) {
                                    const { sendprops = {} } = props
                                    const { user, me } = sendprops
                                    return (user && user.id && me && me.id && user.id === me.id || me && me.isAdmin || me && me.isEditor)
                                }
                            }
                        ]
                    },
                    {
                        name: curlang.wapplruserappplayersname,
                        link: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id) ? routes.wapplruserappplayersslug + '/' + user.id : routes.wapplruserappplayersslug
                        },
                        role: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id && user.status > minuserstatus)
                        },
                        lefticon: <OfferIcon />,
                        items: [
                            {
                                name: curlang.wapplruserappplayerstrash,
                                link: function(props) {
                                    const { sendprops = {} } = props
                                    const { user } = sendprops
                                    return (user && user.id) ? routes.wapplruserappplayersslug + '/trash/' + user.id : routes.wapplruserappplayersslug
                                },
                                lefticon: <OfferIcon />,
                                role: function(props) {
                                    const { sendprops = {} } = props
                                    const { user, me } = sendprops
                                    return (user && user.id && me && me.id && user.id === me.id || me && me.isAdmin || me && me.isEditor)
                                }
                            }
                        ]
                    },
                    {
                        name: curlang.wapplruserappimagesname,
                        link: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id) ? routes.wapplruserappimagesslug + '/' + user.id : routes.wapplruserappimagesslug
                        },
                        role: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id && user.status > minuserstatus)
                        },
                        lefticon: <ImageIcon />,
                        items: [
                            {
                                name: curlang.wapplruserappimagestrash,
                                link: function(props) {
                                    const { sendprops = {} } = props
                                    const { user } = sendprops
                                    return (user && user.id) ? routes.wapplruserappimagesslug + '/trash/' + user.id : routes.wapplruserappimagesslug
                                },
                                lefticon: <ImageIcon />,
                                role: function(props) {
                                    const { sendprops = {} } = props
                                    const { user, me } = sendprops
                                    return (user && user.id && me && me.id && user.id === me.id || me && me.isAdmin || me && me.isEditor)
                                }
                            }
                        ]
                    },
                    {
                        name: curlang.wapplruserappnotificationsname,
                        link: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id) ? routes.wapplruserappnotificationsslug + '/' + user.id : routes.wapplruserappnotificationsslug
                        },
                        role: function(props) {
                            const { sendprops = {} } = props
                            const { user, me } = sendprops
                            return (user && user.id && me && me.id === user.id || me && me.isEditor || me && me.isAdmin)
                        },
                        lefticon: <NotificationsIcon />,
                        items: [
                            {
                                name: curlang.wapplruserappnotificationstrash,
                                link: function(props) {
                                    const { sendprops = {} } = props
                                    const { user } = sendprops
                                    return (user && user.id) ? routes.wapplruserappnotificationsslug + '/trash/' + user.id : routes.wapplruserappnotificationsslug
                                },
                                lefticon: <DeleteIcon />,
                                role: function(props) {
                                    const { sendprops = {} } = props
                                    const { user, me } = sendprops
                                    return (user && user.id && me && me.id && user.id === me.id || me && me.isAdmin || me && me.isEditor)
                                }
                            }
                        ]
                    },
                    {
                        name: curlang.wapplruserappsettingsname,
                        link: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id) ? routes.wapplruserappsettingsslug + '/' + user.id : routes.wapplruserappsettingsslug
                        },
                        role: function(props) {
                            const { sendprops = {} } = props
                            const { user, me } = sendprops
                            return (user && user.id && me && me.id && user.id === me.id)
                        },
                        lefticon: <SettingsIcon />,
                        justicon: true
                    },
                    {
                        name: curlang.wapplruserappsettingsname,
                        link: function(props) {
                            const { sendprops = {} } = props
                            const { user } = sendprops
                            return (user && user.id) ? '/edit' + '/' + user.id : '/edit'
                        },
                        role: function(props) {
                            const { sendprops = {} } = props
                            const { user, me } = sendprops
                            return (user && user.id && me && me.isAdmin && me.id !== user.id || user && user.id && me && me.isEditor && me.id !== user.id)
                        },
                        lefticon: <SettingsIcon />,
                        justicon: true
                    }
                ],
            },
        ]
    }
}
