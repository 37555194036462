// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xfI8x {\n    padding: 10px;\n}\n\n.uZFMH {\n    margin-left: auto;\n    margin-right: auto;\n    display: table;\n    margin-bottom: 10px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userbox": "xfI8x",
	"logoutbutton": "uZFMH"
};
module.exports = ___CSS_LOADER_EXPORT___;
