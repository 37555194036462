import React from 'react'
import createReactClass from 'create-react-class'

import TemplateSettingsbox from '../templates/template_settingsbox'
import Formsy from '../components/Formsy'

export default createReactClass({
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { history, forgotbuttonlink } = this.props

        if (type === 'forgot') {
            if (history && history.push) {
                history.push(forgotbuttonlink)
            }
        }
        e.preventDefault()

    },
    render: function() {

        const {
            style,
            formsystyle,

            changepassdata,
            changepasssubmit,
            changepasssubtitle = 'Set your new password',

            forgotbuttonlabel

        } = this.props

        const touchEnd = this.touchEnd

        return (
            <TemplateSettingsbox {...this.props} title={changepasssubtitle} slug='changepass'>
                <Formsy formdata={changepassdata} submitForm={changepasssubmit} style={formsystyle} />
                {(forgotbuttonlabel) ?
                    <div
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', { type: 'forgot' })
                        }}
                        onTouchStart={function() {
                        }}
                        className={style.forgotbuttoninloginpage}>
                        {forgotbuttonlabel}
                    </div>
                    : null
                }
            </TemplateSettingsbox>
        )
    }
})
