import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import Formsy from '../components/Formsy'

export default createReactClass({

    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },

    render: function() {

        const {
            forgotdata,
            forgotdatawhenloggedin,
            forgotsubmit,
            formsystyle,
            forgotsubtitle = 'Send confirmation email to set new password'
        } = this.props
        const me = this.getMe()

        return (
            <TemplateDefault {...this.props} title={forgotsubtitle} slug='forgot'>
                <Formsy formdata={(me) ? forgotdatawhenloggedin : forgotdata} canSubmit={!!(me)}
                        submitForm={forgotsubmit} style={formsystyle} />
            </TemplateDefault>
        )
    }
})
