import React from 'react'
import createReactClass from 'create-react-class'
import TemplateSettingsbox from '../templates/template_settingsbox'
import Formsy from '../../../../../components/Formsy'
import Wapplrsnackbar from '../../../../../components/Wapplrsnackbar'
import fetchs from '../../../../../services/fetchs'

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            callback: null
        }
    },
    getConstruct: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct : { props: { state: teststate } }
    },
    getHelpers: function() {
        const { construct } = this.props
        const { helpers } = construct.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    historyPush: function(o) {
        const { construct } = this.props
        const { history } = construct.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    componentDidMount: function() {

        const { construct } = this.props
        const { curlang = {}, history, updatefunctions } = construct.props

        const {
            saveavatarfail = 'Sorry, there was an issue save your avatar, please try again',
            saveavatarsuccess = 'Your avatar has been saved'
        } = curlang


        const historyPush = this.historyPush
        const snack = this.snack
        const t = this

        this.changeavatarfetch = fetchs.changeavatar({
            getHelpers: this.getHelpers,
            history: history,
            success: function() {
                snack(saveavatarsuccess)
                let url = window.location.pathname
                if (window.location.search) url = url + window.location.search
                const { callback } = t.state
                if (callback) url = callback

                setTimeout(function() {
                    if (updatefunctions) {
                        updatefunctions.me({
                            callback: function() {
                                historyPush(url)
                            }
                        })
                    } else {
                        if (callback) {
                            window.location.href = url
                        } else {
                            window.location.reload()
                        }
                    }
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || 'gallery'
                    const message = response.message || saveavatarfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        const helpers = this.getHelpers() || {}
        const storedState = helpers.state
        const callback = (storedState && storedState.query && storedState.query.callback) ? decodeURIComponent(storedState.query.callback) : null

        if (callback) {
            this.setState({ callback: callback })
        }

    },
    changeavatarsubmit: function(data, resetForm, invalidateForm, refs) {
        const { user } = this.props
        const rdata = {
            ...data
        }
        rdata.avatarprops = user.avatarprops
        this.changeavatarfetch(rdata, resetForm, invalidateForm, refs)
    },

    setRef: function(a, e) {
        this.refElements[a] = e
    },
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },
    render: function() {



        const {
            formsystyle,
            changeavatardata,
            changeavatarsubtitle = 'Set your avatar image',
            Parent = TemplateSettingsbox,
            user
        } = this.props

        const changeavatarsubmit = this.changeavatarsubmit
        const setRef = this.setRef

        const construct = this.getConstruct()
        const { fetch } = this.getHelpers()


        return (
            <Parent {...this.props} title={changeavatarsubtitle} slug='changeavatar'>
                <Formsy formdata={changeavatardata} submitForm={changeavatarsubmit} style={formsystyle}
                        construct={construct} user={user} fetch={fetch} />
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
            </Parent>
        )
    }

})
