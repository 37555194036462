/*Import dependencies*/
import React from 'react'
import createReactClass from 'create-react-class'

/*Templates*/
import ContentSettings from '../contents/content_settings'
import ContentCustom from '../contents/content_custom'

/*Settings templates*/
import ContentForgot from '../contents/content_forgot'
import ContentChangePassword from '../contents/content_change_password'
import ContentChangeEmail from '../contents/content_change_email'
import ContentChangeBasic from '../contents/content_change_basic'
import ContentConfirmationSend from '../contents/content_confirm_send'
import ContentDeleteAccount from '../contents/content_delete_account'

/*Styles*/
import msdark from '../../../../../../../themes/components/Wapplrmenu.css'
import avs from '../../../../../../../themes/components/Wapplravatar.css'
import s from '../../themes/components/Wapplruser.css'
import fos from '../../themes/components/Formsy.css'

/*Icons and Material theme*/
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme'

/*Account settings*/
import getdefaultaccountsettings from './default_account_settings'

export default function(c) {

    const modulename = c.name || 'wapplrlogin'
    const history = c.history || {}
    const style = c.style || s
    const headerstyle = c.headerstyle || msdark
    const formsystyle = c.formsystyle || fos
    const avatarstyle = c.avatarstyle || avs
    const parentroute = c.parentroute
    const getthumb = c.getthumb
    const getthumbstyle = c.getthumbstyle
    const getavatarclassname = c.getavatarclassname
    const changeavatarhref = c.changeavatarhref
    const changeavatarhovertext = c.changeavatarhovertext
    const popperroot = c.popperroot
    const menuprops = c.menuprops

    lightBaseTheme.palette.primary1Color = c.palette && c.palette.primary1Color || '#3f454a'
    lightBaseTheme.palette.primary2Color = c.palette && c.palette.primary2Color || '#3f454a'

    const menus = c.menus || {
        [modulename]: [{ name: 'Settings', link: '/settings' }]
    }

    function getSettings(input) {

        const accountsettings = [
            {
                name: c.changeemailname || 'Change email',
                lefticon: c.changeemailicon || null,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id)
                },
                items: [
                    { component: ContentChangeEmail, props: input }
                ]
            },
            {
                name: c.changepassname || 'Change password',
                lefticon: c.changepassicon || null,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id)
                },
                items: [
                    { component: ContentChangePassword, props: input }
                ]
            },
            {
                name: c.changebasicname || 'Change name',
                lefticon: c.changebasicicon || null,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id)
                },
                items: [
                    { component: ContentChangeBasic, props: input }
                ]
            },
            {
                divider: 1,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id)
                }
            },
            {
                name: c.confirmsendname || 'Email confirmation',
                lefticon: c.confirmsendicon || null,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && !user.emailisvalidated && me && me.id && user.id === me.id)
                },
                items: [
                    { component: ContentConfirmationSend, props: input }
                ]
            },
            {
                name: c.forgotname || 'Forgot password',
                lefticon: c.forgoticon || null,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id)
                },
                items: [
                    { component: ContentForgot, props: input }
                ]
            },
            {
                name: c.deleteaccountname || 'Delete account',
                lefticon: c.deleteaccounticon || null,
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user, me } = sendprops
                    return (user && user.id && me && me.id && user.id === me.id)
                },
                items: [
                    { component: ContentDeleteAccount, props: input }
                ]
            }
        ]

        let r = (c.getSettings) ? [...c.getSettings(input), ...accountsettings] : [...accountsettings]
        if (c.settingsOrder) {
            r = c.settingsOrder(r)
        }
        return r

    }

    const input = {}
    const settingsdata = getSettings(input)
    const defaultaccountsettings = getdefaultaccountsettings(c)

    const addinput = {
        menus: menus,
        style: style,
        headerstyle: headerstyle,
        avatarstyle: avatarstyle,
        formsystyle: formsystyle,
        history: history,
        parentroute: parentroute,
        popperroot: popperroot,
        muitheme: lightBaseTheme,
        modulename: modulename,
        settingsdata: settingsdata,
        disablesidestyle: c.disablesidestyle,
        getthumb: getthumb,
        getthumbstyle: getthumbstyle,
        getavatarclassname: getavatarclassname,
        changeavatarhref: changeavatarhref,
        changeavatarhovertext: changeavatarhovertext,
        menuprops: menuprops,

        forgotbuttonlabel: c.forgotbuttonlabel,
        forgotbuttonlink: c.forgotbuttonlink,

        ...defaultaccountsettings
    }

    const teststate = {
        response: {
            user: { id: 1, name: { last: 'Last', first: 'First' }, email: 'email@test.com', emailisvalidated: false },
            me: { id: 1, name: { last: 'Last', first: 'First' }, email: 'email@test.com', emailisvalidated: false }
        },
        query: {
            confirmemailkey: 'abc'
        }
    }

    addinput.teststate = teststate

    Object.keys(addinput).map(function(k) {
        input[k] = addinput[k]
    })

    const templates = {
        settings: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentSettings {...input} construct={construct} />
            }
        })
    }

    if (c.pages) {
        Object.keys(c.pages).map(function(k) {

            const { name, template, ...rest } = c.pages[k]

            if (template && name && !templates[name]) {
                templates[name] = createReactClass({
                    render() {
                        const { construct } = this.props
                        return <ContentCustom {...input} construct={construct} CustomPage={template} {...rest} />
                    }
                })
            }

        })
    }

    return templates
}
