export function requests({ name, helpers }) {

    const defHelpName = name + 'Query'

    helpers[defHelpName] = function() {

        return `
            id
            isAdmin
            isEditor
            email
            emailisvalidated
            status
            avatar
            avatarrel {
                id
                cover
            }
            avatarprops
            name {
                first
                last
            }
            registratedDate
            notificationsnumber
            accesstoplayer
            runscript
            disablescriptnotification
        `

    }

    helpers['user'] = function() {

        const imageRelQuery = helpers.imageQuery()

        return `
            id
            isAdmin
            isEditor
            email
            emailisvalidated
            status
            avatar
            avatarprops
            name {
                first
                last
            }
            registratedDate
            notificationsnumber
            avatarrel {
                ${imageRelQuery}
            }
            accesstoplayer
            runscript
            disablescriptnotification
        `

    }

    helpers['defaultUserQuery'] = helpers[defHelpName]

    return {
        ['measuser']: function() {
            return {
                path: '/graphql',
                data: {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: function(p = {}) {

                        const { state, returntype } = p

                        const reqName = 'user'
                        const userQuery = helpers.user()
                        let id = (state && state.user && state.user._id) ? state.user._id : 0
                        if (!id && state && state.user && state.user.id) id = state.user.id
                        if (!id && state && state.response && state.response.me && state.response.me.id) id = state.response.me.id

                        let q = { 'id': id }
                        q = JSON.stringify(q).replace(/"/g, '\'')

                        let s = reqName + `(query:"${q}") {${userQuery}}`

                        if (returntype === 'child') {
                            return s
                        } else {
                            return JSON.stringify({ query: `{${s}}` })
                        }

                    },
                    credentials: 'include'
                }
            }
        },
        [name]: function({ posttypes, taxonomies, storedSearchData, defaultSearchPostType, replacequery}) {
            return {
                path: '/graphql',
                data: {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: function({ state, params }) {

                        const query = (state && state.query) ? state.query : {}
                        const replacequeryfromparams = (params && params.user) ? { user: params.user } : null
                        const mergedreplacequery = { ...replacequeryfromparams, ...replacequery }

                        if (mergedreplacequery) {
                            Object.keys(mergedreplacequery).map(function(k) {
                                if (!query[k]) query[k] = mergedreplacequery[k]
                            })
                        }

                        let id = (query && query.user) ? query.user : null
                        if (!id) id = (query && query.id) ? query.id : null

                        let q = { 'id': id }
                        q = JSON.stringify(q).replace(/"/g, '\'')

                        const url = (state && state.url) ? state.url : ''
                        const foundedarchivepath = query.posttype || helpers.archivepath({ url, posttypes })

                        const userQuery = helpers.user()

                        if (!foundedarchivepath) {

                            return JSON.stringify({
                                query: `{
                              user(query:"${q}") {
                                ${userQuery}
                              }
                            }`
                            })

                        } else {

                            let searchq = helpers.search({
                                state,
                                params,
                                replacequery: { posttype: foundedarchivepath },
                                taxonomies,
                                storedSearchData,
                                defaultSearchPostType,
                                posttypes
                            })

                            let postQuery = null
                            if (searchq.pt) {
                                let ptQueryName = searchq.pt.toLowerCase() + 'Query'
                                if (ptQueryName === 'imageQuery') ptQueryName = 'image'
                                if (helpers[ptQueryName]) postQuery = helpers[ptQueryName]()
                            }
                            if (!postQuery) postQuery = helpers.defaultPostQuery()
                            const postsDataQuery = helpers.defaultPostsDataQuery()
                            const userQuery = helpers.defaultUserQuery()

                            searchq = JSON.stringify(searchq).replace(/"/g, '\'')

                            return JSON.stringify({
                                query: `{
                              user(query: "${q}") {
                                ${userQuery}
                              }
                              archive(query: "${searchq}") {
                                posts {
                                  ${postQuery}
                                }
                                data {
                                  ${postsDataQuery}
                                }
                              }
                            }`
                            })
                        }
                    },
                    credentials: 'include'
                }
            }
        }
    }
}
