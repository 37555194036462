import initwapplruser from './module_wapplruser'
import initwapplrlogin from './module_wapplrlogin'

export function initmodules(p) {

    const { storedConfig = {} } = p
    const { wapplrloginapp, wapplrloginfunctions } = initwapplrlogin(p)
    const { wapplruserapp, wapplruserfunctions } = initwapplruser(p)

    storedConfig.wapplrloginapp = wapplrloginapp
    storedConfig.wapplruserapp = wapplruserapp

    return {
        wapplrloginapp,
        wapplrloginfunctions,
        wapplruserapp,
        wapplruserfunctions
    }

}
