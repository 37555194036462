import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import Formsy from '../components/Formsy'

import Wapplruserbox from '../components/Wapplruserbox'

export default createReactClass({
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { history, forgotbuttonlink } = this.props

        if (type === 'forgot') {
            if (history && history.push) {
                history.push(forgotbuttonlink)
            }
        }
        e.preventDefault()

    },
    render: function() {

        const {
            style,
            logindata,
            loginsubmit,
            formsystyle,
            loginsubtitle = 'Login with email',
            logoutsubmit,
            avatarstyle,
            userboxstyle,
            history,
            logoutbuttontext,
            getthumb,
            getthumbstyle,
            getavatarclassname,
            changeavatarhref,
            changeavatarhovertext,
            forgotbuttonlabel
        } = this.props

        const touchEnd = this.touchEnd
        const me = this.getMe()
        const user = this.getUser()
        const className = (!me || me && !me.id) ? style['page-padding'] : 'emp'

        return (
            <TemplateDefault {...this.props} title={(!me || me && !me.id) ? loginsubtitle : null} className={className}
                             slug='login'>
                {(!me || me && !me.id) ?
                    <div>
                        <Formsy formdata={logindata} submitForm={loginsubmit} style={formsystyle} />
                        {(forgotbuttonlabel) ?
                            <div
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'forgot' })
                                }}
                                onTouchStart={function() {
                                }}
                                className={style.forgotbuttoninloginpage}>
                                {forgotbuttonlabel}
                            </div>
                            : null
                        }
                    </div>
                    :
                    <Wapplruserbox
                        style={userboxstyle}
                        avatarstyle={avatarstyle}
                        user={me}
                        history={history}
                        logoutsubmit={logoutsubmit}
                        logoutbuttontext={logoutbuttontext}
                        getthumb={getthumb} getthumbstyle={getthumbstyle}
                        getavatarclassname={getavatarclassname}
                        href={(me && user && me.id === user.id && changeavatarhref) ? changeavatarhref : null}
                        changeavatarhovertext={(me && user && me.id === user.id && changeavatarhovertext) ? changeavatarhovertext : null}
                    />
                }
            </TemplateDefault>
        )
    }
})
