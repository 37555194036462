export default function(c) {
    /*Set Contents*/

    const contents = {
        'settings': {
            title: c.settingstitle || 'Settings',
            component: 'content',
            content: c.templates.settings,
            history: c.history
        }
    }

    if (c.pages) {
        Object.keys(c.pages).map(function(k) {
            const name = c.pages[k].name
            const title = c.pages[k].title

            if (name) {
                contents[name] = {
                    title: title,
                    component: 'content',
                    content: c.templates[name],
                    history: c.history
                }
            }

        })
    }

    return contents
}
