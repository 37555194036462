export const configroutes = {
    menuuserslug: '/user',
    menuloginslug: '/account',
    wapplruserappusersslug: '/users',

    wapplrloginapploginslug: '/login',
    wapplrloginappsignupslug: '/signup',
    wapplrloginappforgotslug: '/forgot',
    wapplrloginappresetslug: '/reset',
    wapplrloginappconfirmsendslug: '/confirmation/send',
    wapplrloginappconfirmationslug: '/confirmation',
    wapplrloginappchangepassslug: '/changepass',
    wapplrloginappchangeemailslug: '/changeemail',
    wapplrloginappchangebasicslug: '/changebasic',
    wapplrloginappsocial: '/externalservices',
    wapplrloginappchangeavatarslug: '/changeavatar',

    wapplruserappsettingsslug: '/settings',
    wapplruserapppostsslug: '/posts',
    wapplruserappimagesslug: '/images',
    wapplruserappprofileslug: '/profile',
    wapplrloginappdeleteaccountslug: '/deleteaccount'

}
