import React from 'react'

import wapplrlogin from './oldApp'
import style from './themes/components/Wapplrlogin.css'
import avs from '../../../../../themes/components/Wapplravatar.css'
import headerstyle from '../../../../../themes/components/Wapplrmenu.css'
import ContentSocial from './contents/content_social'
import ContentLogin from './contents/content_login'
import ContentSignup from './contents/content_signup'
import ContentNotifications from './contents/content_notifications'
import ContentChangeAvatar from './contents/content_change_avatar'
import usergalleryhelpers from '../../../../components/Usergallery/helpers'
import fetchs from '../../../../services/fetchs'

export default function({
                            curlang,
                            menus,
                            history,
                            routes,
                            getthumb,
                            getthumbstyle,
                            searchData,
                            posttypes,
                            uploadconfig
                        }) {

    const wapplrloginfunctions = {
        login: function(data) {
            console.log(data)
        },
        signup: function(data) {
            console.log(data)
        },
        forgot: function(data) {
            console.log(data)
        },
        reset: function(data) {
            console.log(data)
        },
        logout: function(e, data) {
            console.log(data)
        },
        confirmsend: function(data) {
            console.log(data)
        },
        confirmation: function(data) {
            console.log(data)
        },

        changepass: function(data) {
            console.log(data)
        },
        changeemail: function(data) {
            console.log(data)
        },
        changebasic: function(data) {
            console.log(data)
        },

        deleteaccount: function(data) {
            console.log(data)
        }

    }

    const wapplrloginapp = wapplrlogin({
        name: 'wapplrloginapp',
        avatarstyle: avs,
        style: style,
        headerstyle: headerstyle,
        login: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.login(data, resetForm, invalidateForm)
        },
        signup: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.signup(data, resetForm, invalidateForm)
        },
        forgot: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.forgot(data, resetForm, invalidateForm)
        },
        reset: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.reset(data, resetForm, invalidateForm)
        },
        logout: function(e, data) {
            wapplrloginfunctions.logout(e, data)
        },
        confirmsend: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.confirmsend(data, resetForm, invalidateForm)
        },
        confirmation: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.confirmation(data, resetForm, invalidateForm)
        },

        changepass: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.changepass(data, resetForm, invalidateForm)
        },
        changeemail: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.changeemail(data, resetForm, invalidateForm)
        },
        changebasic: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.changebasic(data, resetForm, invalidateForm)
        },
        deleteaccount: function(data, resetForm, invalidateForm) {
            wapplrloginfunctions.deleteaccount(data, resetForm, invalidateForm)
        },

        pages: {
            changeavatar: {
                name: 'changeavatar',
                title: curlang.wapplrloginappchangeavatarname,
                template: ContentChangeAvatar,
                parentprops: { headertitle: curlang.wapplrloginappchangeavatarnameshort },
                changeavatarsubtitle: curlang.wapplrloginappchangeavatarsubtitle,
                changeavatardata: [
                    usergalleryhelpers({
                        fetchs,
                        curlang,
                        searchData,
                        posttypesData: posttypes,
                        history,
                        routes,
                        uploadconfig,
                        getthumb,
                        config: {
                            editor: function(state, props) {
                                const { user } = props
                                return {
                                    value: function() {
                                        let cp = {}
                                        if (user && user.avatarprops) {
                                            try {
                                                cp = JSON.parse(user.avatarprops)
                                            } catch (e) {
                                            }
                                        }
                                        return cp
                                    },
                                    onChange: function(p = {}) {
                                        const { value } = p
                                        user.avatarprops = (typeof value == 'object') ? JSON.stringify(value) : value
                                    },
                                    image: function() {
                                        if (user && user.avatarrel && typeof user.avatarrel == 'object' && user.avatarrel.cover) {
                                            return user.avatarrel.cover
                                        }
                                        if (user && user.avatar && typeof user.avatar == 'string') {
                                            return user.avatar
                                        }
                                        return ''
                                    },
                                    maskHeightPercent: 100,
                                    showGrid: false
                                }
                            }
                        }
                    }),
                    {
                        type: 'button',
                        attr: {
                            type: 'submit',
                            name: 'submit',
                            label: function(state) {
                                return (state.processing) ? curlang.wapplrloginappavatarsubmit : curlang.wapplrloginappavatarsubmit
                            },
                            style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                            disabled: function(state = {}) {
                                return !state.canSubmit
                            }
                        }
                    }
                ]
            },
            notificationsettings: {
                name: 'notificationsettings',
                title: curlang.wapplrloginappsetnotificationsname,
                template: ContentNotifications,
                parentprops: { headertitle: curlang.wapplrloginappsetnotificationsname }
            },
            social: {
                name: 'social',
                title: curlang.menusociallogintitle,
                template: ContentSocial,
                parentprops: { headertitle: '' }
            },
            login: {
                name: 'login',
                title: curlang.wapplrloginapplogintitle,
                template: ContentLogin,
                parentprops: { headertitle: curlang.wapplrloginapploginheadertitle }
            },
            signup: {
                name: 'signup',
                title: curlang.wapplrloginappsignuptitle,
                template: ContentSignup,
                parentprops: { headertitle: curlang.wapplrloginappsignupheadertitle }
            }
        },

        routes: [
            {
                path: '/',
                data: {
                    content: 'login'
                }
            }, {
                path: routes.wapplrloginapploginslug,
                data: {
                    content: 'login'
                }
            }, {
                path: routes.wapplrloginappsignupslug,
                data: {
                    content: 'signup'
                }
            }, {
                path: routes.wapplrloginappforgotslug,
                data: {
                    content: 'forgot'
                }
            }, {
                path: routes.wapplrloginappresetslug,
                data: {
                    content: 'reset'
                }
            }, {
                path: routes.wapplrloginappconfirmsendslug,
                data: {
                    content: 'confirmsend'
                }
            }, {
                path: routes.wapplrloginappconfirmationslug,
                data: {
                    content: 'confirmation'
                }
            }, {
                path: routes.wapplrloginappchangepassslug,
                data: {
                    content: 'changepassword'
                }
            }, {
                path: routes.wapplrloginappchangeemailslug,
                data: {
                    content: 'changeemail'
                }
            }, {
                path: routes.wapplrloginappchangebasicslug,
                data: {
                    content: 'changebasic'
                }
            }, {
                path: routes.wapplrloginappdeleteaccountslug,
                data: {
                    content: 'deleteaccount'
                }
            }, {
                path: routes.wapplrloginappsocial,
                data: {
                    content: 'social'
                }
            }, {
                path: routes.wapplrloginappchangeavatarslug,
                data: {
                    content: 'changeavatar'
                }
            }
        ],

        parentroute: routes.menuloginslug,
        history: history,
        menus: menus,
        getthumb: getthumb,
        getthumbstyle: getthumbstyle,
        getavatarclassname: function(p = {}) {
            const { style, state } = p
            return style['giant'] + ' ' + style['avatarbox_' + state.type]
        },
        changeavatarhref: function(p = {}) {
            const { user } = p
            let r = routes.menuloginslug + routes.wapplrloginappchangeavatarslug
            if (typeof window !== 'undefined') {
                r = r + '?callback=' + window.location.pathname
            } else if (user && user.id) {
                r = r + '?callback=' + '/account'
            }
            return r
        },
        changeavatarhovertext: curlang.changeavatarhovertext,

        logintitle: curlang.wapplrloginapplogintitle,
        signuptitle: curlang.wapplrloginappsignuptitle,
        forgottitle: curlang.wapplrloginappforgottitle,
        resettitle: curlang.wapplrloginappresettitle,
        confirmsendtitle: curlang.wapplrloginappconfirmsendtitle,
        confirmationtitle: curlang.wapplrloginappconfirmationtitle,

        confirmationisvalidated: curlang.wapplrloginappconfirmationisvalidated,
        confirmationmissingkeytext: curlang.wapplrloginappconfirmationmissingkeytext,
        confirmationprocessingtext: curlang.wapplrloginappconfirmationprocessingtext,
        confirmationloggedouttext: curlang.wapplrloginappconfirmationloggedouttext,
        confirmsendloggedouttext: curlang.wapplrloginappconfirmsendloggedouttext,

        resetmissingkeytext: curlang.wapplrloginappresetmissingkeytext,

        confirmationsubtitle: curlang.wapplrloginappconfirmationsubtitle,
        loginsubtitle: curlang.wapplrloginapploginsubtitle,
        confirmsendsubtitle: curlang.wapplrloginappconfirmsendsubtitle,
        forgotsubtitle: curlang.wapplrloginappforgotsubtitle,
        resetsubtitle: curlang.wapplrloginappresetsubtitle,
        signupsubtitle: curlang.wapplrloginappsignupsubtitle,

        loginheadertitle: curlang.wapplrloginapploginheadertitle,
        signupheadertitle: curlang.wapplrloginappsignupheadertitle,
        logoutheadertitle: curlang.wapplrloginapplogoutheadertitle,
        confirmheadertitle: curlang.wapplrloginappconfirmheadertitle,
        forgotheadertitle: curlang.wapplrloginappforgotheadertitle,
        resetheadertitle: curlang.wapplrloginappresetheadertitle,

        logoutbuttontext: curlang.wapplrloginapplogoutbuttontext,

        changepasssubtitle: curlang.wapplrloginappchangepasssubtitle,
        changepassloggedouttext: curlang.wapplrloginappchangepassloggedouttext,
        changeemailsubtitle: curlang.wapplrloginappchangeemailsubtitle,
        changeemailloggedouttext: curlang.wapplrloginappchangeemailloggedouttext,
        changebasicsubtitle: curlang.wapplrloginappchangebasicsubtitle,
        changebasicloggedouttext: curlang.wapplrloginappchangebasicloggedouttext,
        changepassheadertitle: curlang.wapplrloginappchangepassheadertitle,
        changeemailheadertitle: curlang.wapplrloginappchangeemailheadertitle,
        changebasicheadertitle: curlang.wapplrloginappchangebasicheadertitle,

        changepasswordtitle: curlang.wapplrloginappchangepasswordtitle,
        changeemailwordtitle: curlang.wapplrloginappchangeemailwordtitle,
        changebasicwordtitle: curlang.wapplrloginappchangebasicwordtitle,

        deleteaccountsubtitle: curlang.wapplrloginappdeleteaccountsubtitle,
        deleteaccountloggedouttext: curlang.wapplrloginappdeleteaccountloggedouttext,
        deleteaccountheadertitle: curlang.wapplrloginappdeleteaccountheadertitle,
        deleteaccounttitle: curlang.wapplrloginappdeleteaccounttitle,

        forgotbuttonlabel: curlang.wapplrloginappforgotname,
        forgotbuttonlink: routes.menuloginslug + routes.wapplrloginappforgotslug,

        logindata: [
            {
                type: 'text',
                attr: {
                    name: 'email',
                    hintText: curlang.wapplrloginappemail,
                    floatingLabelText: curlang.wapplrloginappemail,
                    validations: 'isEmail',
                    validationError: curlang.wapplrloginappemailerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginapploginsubmit : curlang.wapplrloginapploginsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        signupdata: [
            {
                type: 'text',
                attr: {
                    name: 'lastname',
                    hintText: curlang.wapplrloginapplastname,
                    floatingLabelText: curlang.wapplrloginapplastname,
                    validations: 'isSpecialWords',
                    validationError: curlang.wapplrloginappspecialwordserror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'firstname',
                    hintText: curlang.wapplrloginappfirstname,
                    floatingLabelText: curlang.wapplrloginappfirstname,
                    validations: 'isSpecialWords',
                    validationError: curlang.wapplrloginappspecialwordserror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'email',
                    hintText: curlang.wapplrloginappemail,
                    floatingLabelText: curlang.wapplrloginappemail,
                    validations: 'isEmail',
                    validationError: curlang.wapplrloginappemailerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    validations: { minLength: 6 },
                    validationError: curlang.wapplrloginappmincharerror(6),
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'repeated_password',
                    hintText: curlang.wapplrloginappconfirmpassword,
                    floatingLabelText: curlang.wapplrloginappconfirmpassword,
                    validations: 'equalsField:password',
                    validationError: curlang.wapplrloginapppasswordequalerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappsignupsubmit : curlang.wapplrloginappsignupsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        forgotdata: [
            {
                type: 'text',
                attr: {
                    name: 'email',
                    hintText: curlang.wapplrloginappemail,
                    floatingLabelText: curlang.wapplrloginappemail,
                    validations: 'isEmail',
                    validationError: curlang.wapplrloginappemailerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappforgotsubmit : curlang.wapplrloginappforgotsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        forgotdatawhenloggedin: [
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappforgotsubmit : curlang.wapplrloginappforgotsubmit
                    },
                    style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        resetdata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    validations: { minLength: 6 },
                    validationError: curlang.wapplrloginappmincharerror(6),
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'repeated_password',
                    hintText: curlang.wapplrloginappconfirmpassword,
                    floatingLabelText: curlang.wapplrloginappconfirmpassword,
                    validations: 'equalsField:password',
                    validationError: curlang.wapplrloginapppasswordequalerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappresetsubmit : curlang.wapplrloginappresetsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        confirmsenddata: [
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappconfirmsendsubmit : curlang.wapplrloginappconfirmsendsubmit
                    },
                    style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],

        changepassdata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginappcurrentpassword,
                    floatingLabelText: curlang.wapplrloginappcurrentpassword,
                    style: { width: '100%' }, required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'new_password',
                    hintText: curlang.wapplrloginappnewpassword,
                    floatingLabelText: curlang.wapplrloginappnewpassword,
                    validations: { minLength: 6 },
                    validationError: curlang.wapplrloginappmincharerror(6),
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'repeated_password',
                    hintText: curlang.wapplrloginappconfirmpassword,
                    floatingLabelText: curlang.wapplrloginappconfirmpassword,
                    validations: 'equalsField:new_password',
                    validationError: curlang.wapplrloginapppasswordequalerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappresetsubmit : curlang.wapplrloginappresetsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        changeemaildata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    style: { width: '100%' }, required: true
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'email',
                    value: function(state, props) {
                        const { construct } = props
                        const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                        const user = (globalstate && globalstate.response && globalstate.response.me) ? globalstate.response.me : null
                        return (user && user.email) ? user.email : ''
                    },
                    hintText: curlang.wapplrloginappemail,
                    floatingLabelText: curlang.wapplrloginappemail,
                    validations: 'isEmail',
                    validationError: curlang.wapplrloginappemailerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappchangeemailsubmit : curlang.wapplrloginappchangeemailsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        changebasicdata: [
            {
                type: 'text',
                attr: {
                    name: 'lastname',
                    value: function(state, props) {
                        const { construct } = props
                        const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                        const user = (globalstate && globalstate.response && globalstate.response.me) ? globalstate.response.me : null
                        return (user && user.name && user.name.last) ? user.name.last : ''
                    },
                    hintText: curlang.wapplrloginapplastname,
                    floatingLabelText: curlang.wapplrloginapplastname + ' *',
                    validations: 'isSpecialWords',
                    validationError: curlang.wapplrloginappspecialwordserror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'firstname',
                    value: function(state, props) {
                        const { construct } = props
                        const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                        const user = (globalstate && globalstate.response && globalstate.response.me) ? globalstate.response.me : null
                        return (user && user.name && user.name.first) ? user.name.first : ''
                    },
                    hintText: curlang.wapplrloginappfirstname,
                    floatingLabelText: curlang.wapplrloginappfirstname,
                    validations: 'isSpecialWords',
                    validationError: curlang.wapplrloginappspecialwordserror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappbasicsubmit : curlang.wapplrloginappbasicsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        deleteaccountdata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    style: { width: '100%' }, required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappdeleteaccountsubmit : curlang.wapplrloginappdeleteaccountsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],

        customprops: {
            configroutes: routes
        }
    })

    return { wapplrloginapp, wapplrloginfunctions }
}
