import { decode } from 'querystring'

export function fetchs({ postNormalise, defaultRequest, defaultEnd, replaceData, defaultFetch, name }) {

    return {

        forgot: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs = {}) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/forgot'
                })
            }
        },
        confirmation: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs = {}) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/confirmation'
                })
            }
        },
        confirmsend: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs = {}) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/confirmation/send'
                })
            }
        },
        reset: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs = {}) {

                const { processingStart, processingEnd } = refs
                const { fetch, state } = getHelpers()

                const rdata = { ...data }
                let search = (history && history.location) ? history.location.search : ''
                if (search.slice(0, 1) === '?') search = search.slice(1)
                const so = (search) ? decode(search) : {}
                rdata.resetkey = so.resetkey
                rdata.email = so.email
                if (rdata.email && rdata.email.match(' ')) rdata.email = rdata.email.replace(' ', '+')

                replaceData({ data: rdata, state, replacefromstate })

                if (fetch) {
                    if (processingStart && processingEnd) processingStart(true)
                    const respData = await defaultFetch({ fetch, data: rdata, link: '/api/auth/reset' })
                    defaultEnd({ respData, processingStart, processingEnd, success, fail, invalidateForm })
                }
            }
        },
        logout: function({ getHelpers, success, fail}) {
            return async function() {
                const { fetch } = getHelpers()
                if (fetch) {
                    const respData = await defaultFetch({ fetch, data: {}, link: '/api/auth/logout' })
                    if (respData.success) {
                        if (success) success()
                    } else {
                        if (fail) fail({ response: respData })
                    }
                }
            }
        },
        login: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/login'
                })
            }
        },
        changepass: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/changepass'
                })
            }
        },
        changeemail: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/changeemail'
                })
            }
        },
        changebasic: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/changebasicdata'
                })
            }
        },
        changeusertype: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/changeusertype'
                })
            }
        },
        changeavatar: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/changeavatar'
                })
            }
        },
        signup: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/signup'
                })
            }
        },
        deleteaccount: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/auth/deleteaccount'
                })
            }
        },


        saveuser: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                postNormalise({ data })
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/save'
                })
            }
        },
        deleteuser: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/delete'
                })
            }
        },
        banuser: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/ban'
                })
            }
        },
        approveuser: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/approve'
                })
            }
        }
    }
}
