import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'

export default createReactClass({
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    render: function() {

        const { CustomPage } = this.props
        const me = this.getMe()
        const user = this.getUser()

        return (
            <TemplateDefault {...this.props} className='emp'>
                {CustomPage ? <CustomPage {...this.props} user={user} me={me} /> : null}
            </TemplateDefault>
        )
    }
})
