import React from 'react'
import createReactClass from 'create-react-class'
import withStyles from 'isomorphic-style-loader/lib/withStyles'

import FlatButton from 'material-ui/FlatButton'
import wapplrcomponents from '../../../../../../../components'
import s from '../empty.css'

const Wapplravatar = wapplrcomponents.avatar

const Userbox = createReactClass({
    getInitialState() {
        return {
            isMounted: false,
            user: null
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {

        const { type } = d
        const { history, logoutsubmit } = this.props

        if (type === 'back') {
            history.go(-1)
        }

        if (type === 'logout') {
            if (logoutsubmit) logoutsubmit(e, this.state.user)
        }

        e.preventDefault()

    },
    setUser: function({ user }) {
        this.setState({
            user: user
        })
    },
    componentDidMount: function() {

        const { user } = this.props
        this.setUser({ user })

        this.setState({
            isMounted: true
        })

    },
    render: function() {
        const {
            style,
            avatarstyle,
            history,
            logoutbuttontext = 'Change another account',
            getthumb,
            getthumbstyle,
            getavatarclassname,
            href,
            changeavatarhovertext
        } = this.props
        const touchEnd = this.touchEnd

        return (
            <div className={style.userbox}>
                <Wapplravatar
                    history={history}
                    user={this.state.user}
                    type={'box'}
                    style={avatarstyle}
                    getthumb={getthumb}
                    getthumbstyle={getthumbstyle}
                    className={getavatarclassname}
                    href={href}
                    hovertext={changeavatarhovertext}
                />
                <div className={style.logoutbutton}>
                    <FlatButton
                        label={logoutbuttontext}
                        onTouchTap={function(e) {
                            touchEnd(e, 'click', 'click', { type: 'logout' })
                        }}
                        onTouchStart={function() {

                        }}
                    />
                </div>
            </div>
        )

    }
})

export default createReactClass({
    render: function() {

        const { style = s, avatarstyle = s, disableInitWithStyles } = this.props
        let R = (style && !disableInitWithStyles) ? withStyles(style)(Userbox) : Userbox
        R = (avatarstyle && !disableInitWithStyles) ? withStyles(avatarstyle)(R) : R
        const input = { ...this.props, style, avatarstyle }

        return (
            <R {...input} />
        )
    }
})
