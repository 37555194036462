import gettemplates from './templates'
import getroutes from './routes'
import getcontents from './contents'
import getstates from './states'
import getfirstreq from './firstrequest'

export function getTemplates(c) {
    return gettemplates(c)
}

export function getRoutes() {
    return getroutes()
}

export function getContents(c) {
    return getcontents(c)
}

export function getStates() {
    return getstates()
}

export function getFirstReq() {
    return getfirstreq()
}
