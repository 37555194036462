import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents, { wapplrhelpers } from '../../../../../../../components'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import Paper from 'material-ui/Paper'
import Subheader from 'material-ui/Subheader'

import SettingsIcon from 'material-ui/svg-icons/action/settings'
import HelpIcon from 'material-ui/svg-icons/action/help'

const Wapplrmenu = wapplrcomponents.menu
const Wapplrcontent = wapplrcomponents.content
const wapplravatarhelpers = wapplrhelpers.avatar

export default createReactClass({
    getInitialState() {
        return {
            className: 'login',
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        const me = (state && state.response && state.response.me) ? state.response.me : null
        if (me) wapplravatarhelpers({ user: me })
        return me
    },
    getSomeData: function() {

        const {
            slug,
            loginheadertitle,
            signupheadertitle,
            logoutheadertitle,
            confirmheadertitle,
            forgotheadertitle,
            resetheadertitle,
            changepassheadertitle,
            changeemailheadertitle,
            changebasicheadertitle,
            deleteaccountheadertitle
        } = this.props
        const me = this.getMe()

        let logoText = ''

        if (!me || me && !me.id) {
            if (slug === 'login') logoText = loginheadertitle || 'Login'
            if (slug === 'signup') logoText = signupheadertitle || 'Signup'
        } else {
            if (slug === 'login') logoText = logoutheadertitle || 'Logout'
            if (slug === 'signup') logoText = logoutheadertitle || 'Logout'
        }
        if (slug === 'confirm') logoText = confirmheadertitle || 'Confirm email'
        if (slug === 'confirmsend') logoText = confirmheadertitle || 'Confirm email'
        if (slug === 'forgot') logoText = forgotheadertitle || 'Forgot password'
        if (slug === 'reset') logoText = resetheadertitle || 'Reset password'

        if (slug === 'changepass') logoText = changepassheadertitle || 'Change password'
        if (slug === 'changeemail') logoText = changeemailheadertitle || 'Change email'
        if (slug === 'changebasic') logoText = changebasicheadertitle || 'Your basic data'

        if (slug === 'deleteaccount') logoText = deleteaccountheadertitle || 'Delete'

        return { logoText, me }

    },
    render: function() {

        const {
            menus,
            muitheme,
            style,
            modulename,
            headerstyle,
            history,
            children,
            parentroute,
            title,
            className,
            headertitle
        } = this.props
        const { logoText, me } = this.getSomeData()
        const ht = (headertitle) ? headertitle : logoText

        const state = this.state

        return (
            <div>
                <MuiThemeProvider muiTheme={getMuiTheme(muitheme)}>
                    <Wapplrcontent style={style}>
                        <Paper zDepth={1}>
                            <div>
                                <div className={style[state.className]}>
                                    <Wapplrmenu
                                        style={headerstyle}
                                        parentroute={parentroute}
                                        menu={menus[modulename]}
                                        fixeddrawer={(menus[modulename + '_fixeddrawer'] && menus[modulename + '_fixeddrawer'].length) ? menus[modulename + '_fixeddrawer'] : null}
                                        history={history}
                                        type='menu'
                                        sendprops={{ user: me }}
                                        logo={ht && <div className={style.headertitle}>{ht}</div>}
                                        Fixedmenuicon={(me && me.id) ? SettingsIcon : HelpIcon}
                                        className={style.appBarWithoutShadow}
                                    />
                                    {(title) ?
                                        <div className={style.subheadercontainer}>
                                            <Subheader className={style.subheader}>
                                                {title}
                                            </Subheader>
                                        </div> : null
                                    }
                                    <div className={className || style['page-padding']}>
                                        {(children) ? children : null}
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Wapplrcontent>
                </MuiThemeProvider>
            </div>
        )
    }
})
