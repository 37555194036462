import React from 'react'
import createReactClass from 'create-react-class'

import ReactDOM from 'react-dom'

import wapplrcomponents from '../../../../../../../components'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import Paper from 'material-ui/Paper'

import Wapplruserbox from '../components/Wapplruserbox'

const Wapplrmenu = wapplrcomponents.menu
const Wapplrcontent = wapplrcomponents.content

export default createReactClass({
    getInitialState() {
        const { disablesidestyle } = this.props
        return {
            className: (disablesidestyle) ? 'userpage' : 'hidden',
            isMounted: false
        }
    },
    updateDimensions: function() {
        const { disablesidestyle } = this.props
        if (!disablesidestyle) {
            let width = ('undefined' == typeof window) ? 1920 : window.innerWidth
            const userpage = this.getElement(this.refElements.userpage)
            if (userpage) width = userpage.offsetWidth
            const breakponint = 800
            const className = (width > breakponint) ? 'userpage-side' : 'userpage'
            if (this.state.className !== className && this.state.isMounted) {
                this.setState({
                    className: className
                })
            }
        }
    },
    getElement: function(e) {
        return (e) ? (e.nodeName) ? e : ReactDOM && ReactDOM.findDOMNode && ReactDOM.findDOMNode(e) : null
    },
    refElements: {},
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    resizeListener: function() {
        const userpage = this.getElement(this.refElements.userpage)
        if (userpage && userpage.offsetWidth !== this.userpageWidth) {
            this.userpageWidth = userpage.offsetWidth
            this.updateDimensions()
        }
    },
    resizeListenerCatchOn: function() {
        this.resizeListener()
        setTimeout(this.resizeListener, 500)
    },
    componentDidMount: function() {
        const { disablesidestyle } = this.props

        this.setState({
            isMounted: true
        })

        if (!disablesidestyle) {
            window.addEventListener('resize', this.updateDimensions)
            document.addEventListener('mouseup', this.resizeListenerCatchOn)
            setTimeout(this.updateDimensions)
        }
    },
    componentWillUnmount: function() {

        const { disablesidestyle } = this.props

        this.setState({
            isMounted: false
        })

        if (!disablesidestyle) {
            window.removeEventListener('resize', this.updateDimensions)
            document.removeEventListener('mouseup', this.resizeListenerCatchOn)
        }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    render: function() {

        const {
            menus, muitheme, style, modulename, headerstyle, avatarstyle, history, children,
            parentroute, className, getthumb, getthumbstyle, popperroot,
            getavatarclassname, changeavatarhref, changeavatarhovertext, menuprops = {}
        } = this.props
        const me = this.getMe()
        const user = this.getUser()
        const state = this.state
        const setRef = this.setRef

        return (
            <div ref={function(e) {
                setRef('userpage', e)
            }}>
                <MuiThemeProvider muiTheme={getMuiTheme(muitheme)}>
                    <Wapplrcontent style={style}>
                        <Paper zDepth={1}>
                            {(user && user.id) ?
                                <div className={style[state.className]}>
                                    <div className={style.userpageleft}>
                                        <Wapplruserbox style={style}
                                                       avatarstyle={avatarstyle}
                                                       user={user}
                                                       history={history}
                                                       enableUserLink={true}
                                                       getthumb={getthumb}
                                                       getthumbstyle={getthumbstyle}
                                                       getavatarclassname={getavatarclassname}
                                                       href={(me && user && me.id === user.id && changeavatarhref) ? changeavatarhref : null}
                                                       changeavatarhovertext={(me && user && me.id === user.id && changeavatarhovertext) ? changeavatarhovertext : null} />
                                    </div>
                                    <div className={style.userpageright}>
                                        <Wapplrmenu
                                            style={headerstyle}
                                            parentroute={parentroute}
                                            menu={menus[modulename]}
                                            fixeddrawer={(menus[modulename + '_fixeddrawer'] && menus[modulename + '_fixeddrawer'].length) ? menus[modulename + '_fixeddrawer'] : null}
                                            history={history}
                                            swapdirection={true}
                                            sendprops={{ user: user, me: me }}
                                            type='menu'
                                            popperRoot={popperroot}
                                            {...menuprops}
                                        />
                                        <div className={className || style['page-padding']}>
                                            {(children) ? children : null}
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                        </Paper>
                    </Wapplrcontent>
                </MuiThemeProvider>
            </div>
        )
    }
})
