import React from 'react'
import createReactClass from 'create-react-class'

import TemplateSettingsbox from '../templates/template_settingsbox'
import Formsy from '../components/Formsy'

export default createReactClass({
    render: function() {

        const {
            forgotdatawhenloggedin,
            forgotsubmit,
            formsystyle,
            forgotsubtitle = 'Send confirmation email to set new password'
        } = this.props

        return (
            <TemplateSettingsbox {...this.props} title={forgotsubtitle} slug='forgot'>
                <Formsy formdata={forgotdatawhenloggedin} canSubmit={true} submitForm={forgotsubmit}
                        style={formsystyle} />
            </TemplateSettingsbox>
        )
    }
})
