export function contents({ curlang, posttypeTemplates, templates, name, c = { storedConfig: {} } }) {

    const meta = c.storedConfig.meta

    const {
        sitenametitle = '',
        description = ''
    } = curlang

    const {
        siteurl = '',
        sitename = '',
        siteimage = ''
    } = meta

    const wapplruserapp = c.storedConfig.wapplruserapp
    const wapplrloginapp = c.storedConfig.wapplrloginapp

    const r = {}
    Object.keys(posttypeTemplates).map(function(t) {
        const tname = (t !== 'user') ? name + t : name

        r[tname] = {
            title: curlang[t + name + 'title'] + sitenametitle,
            component: 'content',
            content: templates[name + t],
            app: (t === 'user') ? wapplruserapp : (t === 'login') ? wapplrloginapp : null,
            siteurl,
            sitename,
            siteimage,
            description
        }
    })

    return r
}
