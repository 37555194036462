export default function(c) {
    /*Set Contents*/
    const contents = {
        'login': {
            title: c.logintitle || 'Login',
            component: 'content',
            content: c.templates.loginpage,
            history: c.history
        },
        'signup': {
            title: c.signuptitle || 'Signup',
            component: 'content',
            content: c.templates.signuppage,
            history: c.history
        },
        'forgot': {
            title: c.forgottitle || 'Forgot password',
            component: 'content',
            content: c.templates.forgotpage,
            history: c.history
        },
        'reset': {
            title: c.resettitle || 'Reset password',
            component: 'content',
            content: c.templates.resetpage,
            history: c.history
        },
        'confirmsend': {
            title: c.confirmsendtitle || 'Send confirmation email',
            component: 'content',
            content: c.templates.confirmsendpage,
            history: c.history
        },
        'confirmation': {
            title: c.confirmationtitle || 'Email confirmation',
            component: 'content',
            content: c.templates.confirmationpage,
            history: c.history
        },
        'changepassword': {
            title: c.changepasswordtitle || 'Change password',
            component: 'content',
            content: c.templates.changepassword,
            history: c.history
        },
        'changeemail': {
            title: c.changeemailwordtitle || 'Change email',
            component: 'content',
            content: c.templates.changeemail,
            history: c.history
        },
        'changebasic': {
            title: c.changebasicwordtitle || 'Change basic data',
            component: 'content',
            content: c.templates.changebasic,
            history: c.history
        },
        'deleteaccount': {
            title: c.deleteaccounttitle || 'Delete account',
            component: 'content',
            content: c.templates.deleteaccount,
            history: c.history
        }
    }

    if (c.pages) {
        Object.keys(c.pages).map(function(k) {
            const name = c.pages[k].name
            const title = c.pages[k].title

            if (name) {
                contents[name] = {
                    title: title,
                    component: 'content',
                    content: c.templates[name],
                    history: c.history
                }
            }

        })
    }

    return contents

}
