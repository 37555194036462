import { routes as iroutes } from './services/routes'
import { configroutes as iconfigroutes } from './services/configroutes'
import content from './contents/content'
import content_edit from './contents/content_edit'
import content_login from './contents/content_login'
import { contents as icontents } from './services/contents'
import { editdata as ieditdata } from './services/editdata'
import { getmenus as igetmenus } from './services/getmenus'
import { initmodules as iinitmodules } from './services/initmodules'
import { fetchs as ifetchs } from './services/fetchs'
import { requests as irequests } from './services/requests'
import { userpageroutes as iuserpageroutes } from './services/userpage_routes'
import { languages as ilanguages } from './languages'

export const configroutes = iconfigroutes
export const routes = iroutes
export const templates = {
    user: content,
    edit: content_edit,
    login: content_login
}

export const userpagetemplates = {}
export const editdata = ieditdata
export const getmenus = igetmenus
export const initmodules = iinitmodules
export const contents = icontents
export const fetchs = ifetchs
export const requests = irequests
export const userpageroutes = iuserpageroutes
export const languages = ilanguages
