import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../../../../../components'
import fs from '../../../../../../themes/components/Wapplrfeed.css'

const Wapplrfeed = wapplrcomponents.feed

createReactClass({
    getInitialState: function() {
        return {
            numbers: this.props.numbers
        }
    },
    render: function() {
        const { numbers } = this.state
        if (numbers) {

            const style = {
                display: 'inline-block',
                backgroundColor: '#ff0000',
                height: '24px',
                width: '24px',
                verticalAlign: 'middle',
                borderRadius: '12px',
                color: '#ffffff',
                lineHeight: '24px',
                fontWeight: 'bold',
                fontSize: '12px'
            }

            return (
                <div style={style}>{numbers}</div>
            )
        }
        return null
    }
})

export default createReactClass({
    getInitialState: function() {
        return {
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({ isMounted: false })
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    getData: function() {
        const user = this.getUser()
        const me = this.getMe()

        const { construct } = this.props
        const {
            routes = {},
            curlang = {}
        } = construct.props

        const {
            menuuserslug,
            wapplruserapppostsslug = '/posts',
            wapplruserappplayersslug = '/players',
            menuplayerslug = '/player',
            menupostslug = '/post'
        } = routes

        let data = []

        if (user && user.id) {
            data = [
                {
                    link: { pathname: menuuserslug + wapplruserappplayersslug + '/' + user.id },
                    title: curlang.wapplruserappplayersname
                }
            ]
        }

        if (user && user.id && user.isAdmin || user && user.id && user.isEditor) {
            data = [
                {
                    link: { pathname: menuuserslug + wapplruserappplayersslug + '/' + user.id },
                    title: curlang.wapplruserappplayersname
                },
                {
                    link: { pathname: menuuserslug + wapplruserapppostsslug + '/' + user.id },
                    title: curlang.wapplruserapppostsname
                },
            ]
        }

        if (me && me.id && user && user.id === me.id && !user.isEditor && !user.isAdmin) {
            data = [
                {
                    link: { pathname: menuuserslug + wapplruserappplayersslug + '/' + user.id },
                    title: curlang.wapplruserappplayersname
                },
                { link: { pathname: menuplayerslug + '/new' }, title: curlang.newplayertitle }
            ]
        }

        if (me && me.id && user && user.id === me.id && me.isAdmin || me && me.id && user && user.id === me.id && me.isEditor) {
            data = [
                {
                    link: { pathname: menuuserslug + wapplruserappplayersslug + '/' + user.id },
                    title: curlang.wapplruserappplayersname
                },
                { link: { pathname: menuplayerslug + '/new' }, title: curlang.newplayertitle },
                {
                    link: { pathname: menuuserslug + wapplruserapppostsslug + '/' + user.id },
                    title: curlang.wapplruserapppostsname
                },
                { link: { pathname: menupostslug + '/new' }, title: curlang.newposttitle },
            ]
        }

        return data
    },
    dashboard: function() {
        return true
    },
    render: function() {

        const { construct } = this.props
        const { style, history, searchData} = construct.props
        const data = this.getData()

        const dashboard = this.dashboard()
        this.getUser()
        const contentcomponent = this.contentcomponent

        return (
            <div>
                {(!dashboard) ?
                    null
                    :
                    <div className={style['page-padding'] + ' ' + style.subpagebg}>
                        <Wapplrfeed
                            history={history}
                            style={fs}
                            data={data}
                            enableLocation={true}
                            enableTitleHref={true}
                            enableThumbHref={true}
                            to={''}
                            selectable={false}
                            searchData={searchData}
                            searchObjectName={null}
                            contentcomponent={contentcomponent}
                            forceClassNameToDisableChangeColums={function(p = {}) {
                                let { className = 'bp1' } = p
                                if (className === 'bp0') className = 'bp1'
                                return className
                            }}
                        />
                    </div>
                }
            </div>
        )
    }
})
