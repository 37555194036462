import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import RaisedButton from 'material-ui/RaisedButton'
import CircularProgress from 'material-ui/CircularProgress'

import {
    FormsyAutoComplete,
    FormsyCheckbox,
    FormsyDate,
    FormsyRadio,
    FormsyRadioGroup,
    FormsySelect,
    FormsyText,
    FormsyTime,
    FormsyToggle
} from 'formsy-material-ui/lib'
import s from '../empty.css'

import Formsy from 'formsy-react'

const comps = {
    text: FormsyText,
    button: RaisedButton,
    date: FormsyDate,
    radio: FormsyRadio,
    checkbox: FormsyCheckbox,
    radioGroup: FormsyRadioGroup,
    select: FormsySelect,
    time: FormsyTime,
    toggle: FormsyToggle,
    autocomplete: FormsyAutoComplete
}

const Formitems = createReactClass({

    componentDidMount() {
    },

    render: function() {
        const { style, setRef, state } = this.props
        const props = this.props
        const formdata = state.items || []

        return (
            <div>
                {formdata.map(function(itemdata, formindex) {

                    const C = comps[itemdata.type]
                    if (!C) return <div />

                    const attr = {
                        name: 'wapplr_' + formindex
                    }

                    Object.keys(itemdata.attr).forEach(function(i) {
                        const attribute = itemdata.attr[i]
                        if (attribute) {
                            attr[i] = (typeof attribute == 'function') ? attribute(state, props) : attribute
                        }
                    })

                    return (
                        <div className={style[itemdata.type]} key={formindex}>
                            {(itemdata.type === 'button' && state.processing) ?
                                <C ref={function(e) {
                                    setRef(attr.name, e)
                                }} {...attr} icon={<CircularProgress size={24} thickness={3} />} /> :
                                <C ref={function(e) {
                                    setRef(attr.name, e)
                                }} {...attr} />
                            }
                        </div>
                    )
                })}
            </div>
        )
    }
})

const Forms = createReactClass({

    getInitialState() {
        return {
            canSubmit: this.props.canSubmit || false,
            processing: false,
            c: false,
            items: []
        }
    },

    enableButton() {
        this.setState({
            canSubmit: true
        })
    },

    disableButton() {
        this.setState({
            canSubmit: false
        })
    },

    processingStart(disable) {
        this.setState({
            processing: true,
            canSubmit: !disable
        })
    },

    processingEnd(disable) {
        this.setState({
            processing: false,
            canSubmit: !disable
        })
    },

    submitForm(a, b, c) {
        this.onSubmit(a, b, c, {
            elements: this.state.elements,
            disableButton: this.disableButton,
            enableButton: this.enableButton,
            processingStart: this.processingStart,
            processingEnd: this.processingEnd
        })
    },
    onSubmit: function() {
    },
    setRef: function(a, e) {
        if (!this.state.elements) this.state.elements = {}
        this.state.elements[a] = e
    },

    notifyFormError(data) {
        console.error('Form error:', data)
    },

    componentDidMount() {
        const { submitForm } = this.props
        if (submitForm) this.onSubmit = submitForm
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    render() {
        const { formdata = [] } = this.props

        this.state.items = formdata
        const input = { ...this.props, setRef: this.setRef, state: this.state }

        return (
            <div>
                <div>
                    <Formsy.Form
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        onValidSubmit={this.submitForm}
                        onInvalidSubmit={this.notifyFormError}
                    >
                        <div>
                            {(this.state.isMounted) ? <Formitems {...input} /> : null}
                        </div>
                    </Formsy.Form>
                </div>
            </div>
        )
    }
})

export default createReactClass({
    render: function() {
        const { style = s, disableInitWithStyles } = this.props

        const R = (style && !disableInitWithStyles) ? withStyles(style)(Forms) : Forms
        const input = { ...this.props, style }

        return (
            <R {...input} />
        )
    }
})
