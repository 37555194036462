import React from 'react'

import wapplruser from './oldApp'
import style from './themes/components/Wapplruser.css'
import avs from '../../../../../themes/components/Wapplravatar.css'
import headerstyle from '../../../../../themes/components/Wapplrmenu.css'
import cstyle from '../../../../customstyle/default/index.css'

import UserIcon from 'material-ui/svg-icons/action/perm-identity'
import HelpIcon from 'material-ui/svg-icons/action/help'
import LockIcon from 'material-ui/svg-icons/action/lock-outline'
import EmailIcon from 'material-ui/svg-icons/communication/email'
import EmailIcon1 from 'material-ui/svg-icons/communication/mail-outline'
import DeleteIcon from 'material-ui/svg-icons/action/delete-forever'
import PersonIcon from 'material-ui/svg-icons/social/person'

import usergalleryhelpers from '../../../../components/Usergallery/helpers'

import ContentUserRoot from './contents/content_user_root'
import ContentSettingsChangeAvatar from './contents/content_change_avatar'

import { poststatuses } from '../../../../server/poststatus/statuses'
import fetchs from '../../../../services/fetchs'

export default function({
                            curlang,
                            menus,
                            history,
                            routes,
                            posttypes,
                            taxonomies,
                            searchData,
                            defaultSearchPostType,
                            uploadconfig,
                            getthumb,
                            getthumbstyle,
                            requests,
                            posttypesPlugins
                        }) {

    const storedSearchData = searchData

    const wapplruserfunctions = {
        forgot: function(data) {
            console.log(data)
        },
        confirmsend: function(data) {
            console.log(data)
        },
        changepass: function(data) {
            console.log(data)
        },
        changeemail: function(data) {
            console.log(data)
        },
        changebasic: function(data) {
            console.log(data)
        },
        deleteaccount: function(data) {
            console.log(data)
        }

    }

    const userRequest = requests.user({
        posttypes,
        taxonomies,
        storedSearchData,
        defaultSearchPostType,
        replacequery: { sgt: (poststatuses.created - 1) },
        name: 'userRequest'
    })

    const wapplruserapp = wapplruser({
        name: 'wapplruserapp',
        style: style,
        disablesidestyle: true,
        headerstyle: headerstyle,
        avatarstyle: avs,
        routes: [
            {
                path: '/',
                data: {
                    content: 'userroot',
                    fetchreq: userRequest
                }
            },
            {
                path: routes.wapplruserappsettingsslug,
                data: {
                    content: 'settings',
                    fetchreq: userRequest
                }
            },
            ...posttypesPlugins.userpageroutes({
                requests,
                configroutes: routes,
                poststatuses,
                userRequest,
                posttypes,
                taxonomies,
                storedSearchData,
                defaultSearchPostType
            })
        ],
        parentroute: routes.menuuserslug,
        popperroot: (typeof window == 'undefined') ? null : null,
        history: history,
        menus: menus,
        getthumb: getthumb,
        getthumbstyle: getthumbstyle,
        getavatarclassname: function(p = {}) {
            const { style, state } = p
            let rClassName = style['giant'] + ' ' + style['avatarbox_' + state.type]
            return rClassName
        },
        changeavatarhref: function(p = {}) {
            const { user } = p
            let r = routes.menuloginslug + routes.wapplrloginappchangeavatarslug
            if (user && user.id) {
                r = r + '?callback=' + '/user' + '/' + user.id
            } else if (typeof window !== 'undefined') {
                r = r + '?callback=' + window.location.pathname
            }
            return r
        },
        changeavatarhovertext: curlang.changeavatarhovertext,

        pages: {
            root: {
                name: 'userroot',
                title: curlang.menuusertitle,
                template: ContentUserRoot
            },
            ...posttypesPlugins.userpagetemplates({ curlang })
        },

        /*Acoount settings*/

        settingstitle: curlang.wapplruserappsettingstitle,

        forgot: function(data, resetForm, invalidateForm) {
            wapplruserfunctions.forgot(data, resetForm, invalidateForm)
        },
        confirmsend: function(data, resetForm, invalidateForm) {
            wapplruserfunctions.confirmsend(data, resetForm, invalidateForm)
        },
        changepass: function(data, resetForm, invalidateForm) {
            wapplruserfunctions.changepass(data, resetForm, invalidateForm)
        },
        changeemail: function(data, resetForm, invalidateForm) {
            wapplruserfunctions.changeemail(data, resetForm, invalidateForm)
        },
        changebasic: function(data, resetForm, invalidateForm) {
            wapplruserfunctions.changebasic(data, resetForm, invalidateForm)
        },
        deleteaccount: function(data, resetForm, invalidateForm) {
            wapplruserfunctions.deleteaccount(data, resetForm, invalidateForm)
        },

        confirmationisvalidated: curlang.wapplrloginappconfirmationisvalidated,
        confirmsendsubtitle: curlang.wapplrloginappconfirmsendsubtitle,
        forgotsubtitle: curlang.wapplrloginappforgotsubtitle,
        changepasssubtitle: curlang.wapplrloginappchangepasssubtitle,
        changeemailsubtitle: curlang.wapplrloginappchangeemailsubtitle,
        changebasicsubtitle: curlang.wapplrloginappchangebasicsubtitle,
        deleteaccountsubtitle: curlang.wapplrloginappdeleteaccountsubtitle,

        changeemailname: curlang.wapplrloginappchangeemailname,
        changepassname: curlang.wapplrloginappchangepassname,
        changebasicname: curlang.wapplrloginappchangebasicname,
        confirmsendname: curlang.wapplrloginappconfirmsendname,
        forgotname: curlang.wapplrloginappforgotname,
        deleteaccountname: curlang.wapplrloginappdeleteaccountname,

        changeemailicon: <EmailIcon1 />,
        changepassicon: <LockIcon />,
        changebasicicon: <UserIcon />,
        confirmsendicon: <EmailIcon />,
        forgoticon: <HelpIcon />,
        deleteaccounticon: <DeleteIcon />,

        forgotbuttonlabel: curlang.wapplrloginappforgotname,
        forgotbuttonlink: routes.menuloginslug + routes.wapplrloginappforgotslug,

        settingsOrder: function(settings) {
            const r = [settings[0], settings[4]]
            settings.map(function(m, i) {
                if (i !== 0 && i !== 4) r.push(m)
            })
            return r
        },

        getSettings: function(input) {
            return [
                {
                    name: curlang.wapplrloginappchangeavatarname,
                    lefticon: <PersonIcon />,
                    role: function(props) {
                        const { sendprops = {} } = props
                        const { user, me } = sendprops
                        return (user && user.id && me && me.id && user.id === me.id)
                    },
                    items: [
                        {
                            component: ContentSettingsChangeAvatar,
                            props: {
                                ...input,
                                style: cstyle,
                                changeavatarsubtitle: curlang.wapplrloginappchangeavatarsubtitle,
                                changeavatardata: [
                                    usergalleryhelpers({
                                        fetchs,
                                        curlang,
                                        searchData,
                                        posttypesData: posttypes,
                                        history,
                                        routes,
                                        uploadconfig,
                                        getthumb,
                                        config: {
                                            editor: function(state, props) {
                                                const { user } = props
                                                return {
                                                    value: function() {
                                                        let cp = {}
                                                        if (user && user.avatarprops) {
                                                            try {
                                                                cp = JSON.parse(user.avatarprops)
                                                            } catch (e) {
                                                            }
                                                        }
                                                        return cp
                                                    },
                                                    onChange: function(p = {}) {
                                                        const { value } = p
                                                        const v = (typeof value == 'object') ? JSON.stringify(value) : value
                                                        user.avatarprops = v
                                                        //console.log(user.avatarprops);
                                                    },
                                                    image: function() {
                                                        if (user && user.avatarrel && typeof user.avatarrel == 'object' && user.avatarrel.cover) {
                                                            return user.avatarrel.cover
                                                        }
                                                        if (user && user.avatar && typeof user.avatar == 'string') {
                                                            return user.avatar
                                                        }
                                                        return ''
                                                    },
                                                    maskHeightPercent: 100,
                                                    showGrid: false
                                                }
                                            }
                                        }
                                    }),
                                    {
                                        type: 'button',
                                        attr: {
                                            type: 'submit',
                                            name: 'submit',
                                            label: function(state) {
                                                return (state.processing) ? curlang.wapplrloginappavatarsubmit : curlang.wapplrloginappavatarsubmit
                                            },
                                            style: {
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                display: 'table',
                                                width: 'auto'
                                            },
                                            disabled: function(state = {}) {
                                                return !state.canSubmit
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        },

        forgotdatawhenloggedin: [
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappforgotsubmit : curlang.wapplrloginappforgotsubmit
                    },
                    style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],

        confirmsenddata: [
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappconfirmsendsubmit : curlang.wapplrloginappconfirmsendsubmit
                    },
                    style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],

        changepassdata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginappcurrentpassword,
                    floatingLabelText: curlang.wapplrloginappcurrentpassword,
                    style: { width: '100%' }, required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'new_password',
                    hintText: curlang.wapplrloginappnewpassword,
                    floatingLabelText: curlang.wapplrloginappnewpassword,
                    validations: { minLength: 6 },
                    validationError: curlang.wapplrloginappmincharerror(6),
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'repeated_password',
                    hintText: curlang.wapplrloginappconfirmpassword,
                    floatingLabelText: curlang.wapplrloginappconfirmpassword,
                    validations: 'equalsField:new_password',
                    validationError: curlang.wapplrloginapppasswordequalerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappresetsubmit : curlang.wapplrloginappresetsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        changeemaildata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    style: { width: '100%' }, required: true
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'email',
                    value: function(state, props) {
                        const { construct } = props
                        const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                        const user = (globalstate && globalstate.response && globalstate.response.user) ? globalstate.response.user : null
                        return (user && user.email) ? user.email : ''
                    },
                    hintText: curlang.wapplrloginappemail,
                    floatingLabelText: curlang.wapplrloginappemail,
                    validations: 'isEmail',
                    validationError: curlang.wapplrloginappemailerror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappchangeemailsubmit : curlang.wapplrloginappchangeemailsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        changebasicdata: [
            {
                type: 'text',
                attr: {
                    name: 'lastname',
                    value: function(state, props) {
                        const { construct } = props
                        const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                        const user = (globalstate && globalstate.response && globalstate.response.user) ? globalstate.response.user : null
                        return (user && user.name && user.name.last) ? user.name.last : ''
                    },
                    hintText: curlang.wapplrloginapplastname,
                    floatingLabelText: curlang.wapplrloginapplastname + ' *',
                    validations: 'isSpecialWords',
                    validationError: curlang.wapplrloginappspecialwordserror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'firstname',
                    value: function(state, props) {
                        const { construct } = props
                        const globalstate = (construct.props && construct.props.state) ? construct.props.state : null
                        const user = (globalstate && globalstate.response && globalstate.response.user) ? globalstate.response.user : null
                        return (user && user.name && user.name.first) ? user.name.first : ''
                    },
                    hintText: curlang.wapplrloginappfirstname,
                    floatingLabelText: curlang.wapplrloginappfirstname + ' *',
                    validations: 'isSpecialWords',
                    validationError: curlang.wapplrloginappspecialwordserror,
                    style: { width: '100%' },
                    required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappbasicsubmit : curlang.wapplrloginappbasicsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        deleteaccountdata: [
            {
                type: 'text',
                attr: {
                    type: 'password',
                    name: 'password',
                    hintText: curlang.wapplrloginapppassword,
                    floatingLabelText: curlang.wapplrloginapppassword,
                    style: { width: '100%' }, required: true
                }
            },
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state) {
                        return (state.processing) ? curlang.wapplrloginappdeleteaccountsubmit : curlang.wapplrloginappdeleteaccountsubmit
                    },
                    style: { marginTop: 32 },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        menuprops: {
            breakpoint: 320
        }

    })

    return { wapplruserapp, wapplruserfunctions }
}
