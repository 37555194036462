// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vLhpD {\n    display: block;\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.ZEYd- {\n    display: block;\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.ZEYd- svg {\n    display: inline-block !important;\n    color: rgb(255, 180, 0) !important;\n    height: 100% !important;\n    width: 100% !important;\n}\n\n.Uq-xL {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    color: #ffffff;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.G99UD {\n    padding-left: 3px;\n    padding-right: 3px;\n    background-color: rgb(255, 180, 0);\n    line-height: normal;\n    border-radius: 10px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "vLhpD",
	"icon": "ZEYd-",
	"value": "Uq-xL",
	"valuein": "G99UD"
};
module.exports = ___CSS_LOADER_EXPORT___;
