import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import Formsy from '../components/Formsy'

import { decode } from 'querystring'

export default createReactClass({
    getInitialState() {
        const key = this.getKey()
        return {
            isMounted: false,
            key: key || null,
            error: null
        }
    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getKey: function() {
        const state = this.getGlobalState()
        if (state && state.query && state.query.resetkey) return state.query.resetkey

        const { history } = this.props
        let search = (history && history.location) ? history.location.search : ''
        if (search.slice(0, 1) === '?') search = search.slice(1)
        const so = (search) ? decode(search) : {}
        return so.resetkey
    },
    render: function() {

        const {
            style,
            resetdata,
            resetsubmit,
            formsystyle,
            resetsubtitle = 'Set your new password',
            resetmissingkeytext = 'Missing key'
        } = this.props

        return (
            <TemplateDefault {...this.props} title={resetsubtitle} slug='reset'>
                {!(this.state.key) ?
                    <div className={style.centerinfo}>{resetmissingkeytext}</div>
                    :
                    <Formsy formdata={resetdata} submitForm={resetsubmit} style={formsystyle} />
                }
            </TemplateDefault>
        )
    }
})
