function hasNumbers(t) {
    const regex = /\d/g
    return regex.test(t)
}

export function routes({ configroutes, defaultRootAction, requests, c, poststatuses, name }) {

    const taxonomies = c.storedConfig.dataTaxonomies
    const posttypes = c.storedConfig.dataPosttypes
    const storedSearchData = c.storedConfig.storedSearchData
    const defaultSearchPostType = c.storedConfig.defaultSearchPostType || 'post'


    const wapplrloginapp = c.storedConfig.wapplrloginapp
    const wapplruserapp = c.storedConfig.wapplruserapp

    const userRequest = requests.user({
        posttypes,
        taxonomies,
        storedSearchData,
        defaultSearchPostType,
        replacequery: { sgt: (poststatuses.created - 1) }
    })

    async function finduser({ next, universalPath, r, getContent }) {

        let findpath = universalPath
        let userid = 0
        let findpathobj = []
        if (findpath && hasNumbers(findpath)) {

            if (findpath.slice(0, 1) === '/') findpath = findpath.slice(1)
            if (findpath.slice(-1) === '/') findpath = findpath.slice(0, -1)

            findpathobj = findpath.split('/')

            if (findpathobj.length) {
                const last = findpathobj[findpathobj.length - 1]
                if (last && hasNumbers(last)) {
                    userid = last
                    findpath = findpath.replace('/' + userid, '')
                    findpath = '/' + findpath
                }
            }
        }

        wapplruserapp.startroute = (findpathobj.length === 1) ? '/' : findpath
        const wapplrcontentdata = getContent(wapplruserapp)

        if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
            const routedata = wapplrcontentdata.data
            r.data.fetchreq = routedata.fetchreq
            r.data.content.getFetch = null
            r.data.content.route = findpath
            r.title = wapplrcontentdata.content.title
            if (userid) r.data.params = { user: userid }

            return r
        }

        return await next()

    }

    return [
        {
            path: configroutes.menuloginslug,
            data: {
                content: 'userlogin',
                disablefetchcall: false,
                fetchreq: requests.measuser(),
                callback: defaultRootAction
            },
            children: [
                {
                    path: '*',
                    data: {
                        startroute: configroutes.menuloginslug,
                        content: 'userlogin',
                        disablefetchcall: true,
                        callback: async function({ universalPath, r, getContent }) {
                            wapplrloginapp.startroute = universalPath
                            const wapplrcontentdata = getContent(wapplrloginapp)

                            if (wapplrcontentdata.content && wapplrcontentdata.status !== 404) {
                                const routedata = wapplrcontentdata.data
                                r.data.fetchreq = routedata.fetchreq
                                r.data.content.getFetch = null
                                r.title = wapplrcontentdata.content.title
                                return r
                            }

                            r.status = 404
                            r.title = 'Not Found'
                            return r
                        }
                    }
                }
            ]
        },
        {
            path: configroutes['menu' + name + 'slug'] + '/edit',
            data: {
                content: name + 'edit',
                fetchreq: userRequest,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/edit',
                        content: name + 'edit',
                        disablefetchcall: true,
                        fetchreq: userRequest
                    }
                }
            ]
        },
        {
            path: configroutes['menu' + name + 'slug'],
            data: {
                content: 'user',
                fetchreq: userRequest,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: 'user',
                        fetchreq: userRequest
                    }
                },
                {
                    path: '/:userpage/:user',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: 'user',
                        disablefetchcall: true,
                        callback: finduser
                    }
                }, {
                    path: '/:userpage/*/:user',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: 'user',
                        disablefetchcall: true,
                        callback: finduser
                    }
                }, {
                    path: '/:user',
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: 'user',
                        disablefetchcall: true,
                        callback: finduser
                    }
                }
            ]
        }
    ]
}
