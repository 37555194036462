import React from 'react'
import createReactClass from 'create-react-class'

import withStyles from 'isomorphic-style-loader/lib/withStyles'

import wapplrcomponents from '../../../../../../../../components'

import Badge from '../Badge'
import bds from '../Badge/Badge.css'

import s from '../empty.css'

const Wapplravatar = wapplrcomponents.avatar

const Userbox = createReactClass({
    getInitialState() {
        const { user } = this.props
        return {
            isMounted: false,
            user: user || null
        }
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { history} = this.props

        if (type === 'back') {
            history.go(-1)
        }
        e.preventDefault()
    },
    componentDidMount: function() {

        const { user } = this.props

        this.setState({
            isMounted: true,
            user: user
        })

    },
    render: function() {
        const {
            style = s,
            avatarstyle = s,
            enableUserLink,
            history,
            getthumb,
            getthumbstyle,
            getavatarclassname,
            href,
            changeavatarhovertext
        } = this.props


        const user = this.state.user

        return (
            <div className={style.userbox}>
                <Wapplravatar
                    user={user}
                    type={'box'}
                    style={avatarstyle}
                    enableUserLink={enableUserLink}
                    history={history}
                    getthumb={getthumb}
                    getthumbstyle={getthumbstyle}
                    className={getavatarclassname}
                    href={href}
                    hovertext={changeavatarhovertext}
                    badge={(user && user.starrating) ? <Badge style={bds} value={user.starrating} /> : null}
                />
            </div>
        )

    }
})

export default createReactClass({
    render: function() {
        const { style, avatarstyle, disableInitWithStyles } = this.props

        let R = (style && !disableInitWithStyles) ? withStyles(style)(Userbox) : Userbox
        R = (avatarstyle && !disableInitWithStyles) ? withStyles(avatarstyle)(R) : R
        const input = { ...this.props, style, avatarstyle }

        return (
            <R {...input} />
        )
    }
})
