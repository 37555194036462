import React from 'react'
import createReactClass from 'create-react-class'

import ps from '../../../../themes/components/Wapplrpost.css'
import avs from '../../../../themes/components/Wapplravatar.css'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import Wapplrsnackbar from '../../../components/Wapplrsnackbar'
import Wapplrdialog from '../../../components/Wapplrdialog'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'

import fetchs from '../../../services/fetchs'
import { getUserStatusData } from '../../../server/userstatus/statuses'

import { Step, StepButton, StepLabel, Stepper } from 'material-ui/Stepper'

const WapplrpostEdit = wapplrcomponents.editpost

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            isMounted: false,
            post: null,
            stepIndex: 0,
            orientation: null
        }
    },
    updateDimensions: function(ret) {
        const state = (this.state) ? this.state : {}
        const { orientation = 'horizontal' } = state
        const width = (typeof window !== 'undefined') ? window.innerWidth : 0
        const neworientation = (width > 800) ? 'horizontal' : 'vertical'
        if (ret === true) {
            return neworientation
        } else {
            if (neworientation !== orientation) {
                this.setState({
                    orientation: neworientation
                })
            }
        }
    },
    setSaved: function() {
        const editpost = this.refElements['editpost']
        if (editpost && editpost.updatePost) editpost.updatePost()
    },
    componentDidMount: function() {

        const { response, curlang = {}, history } = this.props

        const {
            savepostdefaultfail = 'Sorry, there was an issue save your post, please try again',
            savepostsuccessmessage = 'Your post has been saved',
            deletepostdefaultfail = 'Sorry, there was an issue delete your post, please try again',
            deletepostsuccessmessage = 'Your post has been deleted'
        } = curlang

        const post = this.getPost(response)
        const getPostLink = this.getPostLink
        const snack = this.snack
        const setSaved = this.setSaved
        const done = this.done

        this.savefetch = fetchs.saveuser({
            getHelpers: this.getHelpers,
            history: history,
            success: function({ response, processingEnd }) {
                snack(savepostsuccessmessage)
                if (processingEnd) processingEnd(false)
                if (response.status) post.status = response.status
                setSaved()
                setTimeout(function() {
                    done(getPostLink({ id: response.postId }))
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || savepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        const dialogClose = this.dialogClose

        this.deletefetch = fetchs.deleteuser({
            getHelpers: this.getHelpers,
            history: history,
            success: function({ response }) {
                dialogClose()
                snack(deletepostsuccessmessage)
                setTimeout(function() {
                    done(getPostLink({ id: response.postId }))
                }, 1500)
            },
            fail: function({ response, invalidateForm }) {
                if (response.messages) {
                    invalidateForm(response.messages)
                } else {
                    const field = response.field || ''
                    const message = response.message || deletepostdefaultfail
                    if (field && message) invalidateForm({ [field]: message })
                }
            }
        })

        this.setState({
            isMounted: true,
            post: post,
            orientation: this.updateDimensions(true)
        })

        window.addEventListener('resize', this.updateDimensions)

    },

    componentWillUnmount: function() {

        window.removeEventListener('resize', this.updateDimensions)

        this.setState({
            isMounted: false
        })
    },

    //Close

    close: function() {
        const { history } = this.props
        const { state } = this.getHelpers()

        const getPostLink = this.getPostLink

        const historyPush = this.historyPush

        if (state.lasturl) {
            history.go(-1)
        } else {
            historyPush(getPostLink())
        }
    },

    done: function(post) {

        const { state } = this.getHelpers()

        const getPostLink = this.getPostLink

        const historyPush = this.historyPush

        if (state.lasturl) {
            historyPush(state.lasturl)
        } else {
            historyPush(getPostLink(post))
        }
    },

    //Edit functions

    savefetch: function() {
    },
    savepost: function(data, resetForm, invalidateForm, refs = {}) {

        const { stepIndex, saveddata = {}, post } = this.state
        const stepdata = (this.stepdata) ? this.stepdata : {}

        const rdata = {
            ...saveddata,
            ...data
        }

        rdata.id = (post && post.id) ? post.id : 0
        rdata.avatarprops = post.avatarprops

        this.stepdata = {
            ...stepdata,
            [stepIndex]: {
                data,
                invalidateForm
            }
        }

        const rinvalidateForm = this.invalidateForm
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) processingStart(true)
        this.savefetch(rdata, resetForm, rinvalidateForm, refs)
    },
    invalidateForm: function(messages = {}) {

        const stepdata = (this.stepdata) ? this.stepdata : {}
        const messagesbystep = {}

        let foundstep = false

        Object.keys(messages).map(function(field) {
            Object.keys(stepdata).map(function(stepindex) {
                const step = stepdata[stepindex]
                const data = step.data
                if (data) {
                    Object.keys(data).map(function(v) {
                        if (v === field) {
                            if (!messagesbystep[stepindex]) {
                                if (foundstep === false) foundstep = stepindex
                                messagesbystep[stepindex] = {}
                            }
                            messagesbystep[stepindex][field] = messages[field]
                        }
                    })
                }
            })
        })

        if (foundstep === false && foundstep !== 0 && foundstep !== '0') {
            const snack = this.snack
            let rm = ''
            Object.keys(messages).map(function(m) {
                rm = (!rm) ? messages[m] : rm + '; ' + messages[m]
            })
            if (rm) snack(rm)
        } else {

            const rmessages = messagesbystep[foundstep]
            this.setState({
                stepIndex: Number(foundstep)
            })
            const refElements = this.refElements
            setTimeout(function() {
                const formsy = refElements['formsy_formsy']
                if (formsy && formsy.updateInputsWithError) formsy.updateInputsWithError(rmessages)
            }, 500)
        }

    },
    nextstep: function(data) {
        const { stepIndex, saveddata = {} } = this.state
        const stepdata = (this.stepdata) ? this.stepdata : {}

        this.stepdata = {
            ...stepdata,
            [stepIndex]: {
                data
            }
        }

        this.setState({
            stepIndex: stepIndex + 1,
            saveddata: {
                ...saveddata,
                ...data
            }
        })
    },
    getstep: function(step) {
        const { stepIndex } = this.state
        if (stepIndex > step) {
            this.setState({
                stepIndex: Number(step)
            })
        }
    },
    deletefetch: function() {
    },
    deletepost: function({ post }) {

        const { curlang } = this.props

        const {
            deleteusersubtitle = 'Delete user',
            deleteuserquestion = 'Are you sure want to delete your user? (as delete itself)',
            submittext = 'Submit',
            canceltext = 'Cancel'
        } = curlang

        const deletefetch = this.deletefetch
        const dialogClose = this.dialogClose

        const { processingStart, processingEnd } = this.dialogOpen({
            title: deleteusersubtitle,
            text: deleteuserquestion,
            submittext: submittext,
            canceltext: canceltext,
            action: function() {

                const data = { id: post.id }
                const resetForm = function() {
                }
                const invalidateForm = function(data) {
                    console.log(data)
                    dialogClose()
                }
                const refs = {
                    processingEnd: function() {
                        if (processingEnd) processingEnd()
                    },
                    processingStart: function() {
                        if (processingStart) processingStart()
                    }
                }
                deletefetch(data, resetForm, invalidateForm, refs)

            }
        })
    },

    getPostLinkObject: function() {
        const post = this.state.post
        if (post && post.id) {
            return {
                pathname: '/user/' + post.id
            }
        }
        return {}
    },
    getPostLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/user/' + post.id
        }
        return ''
    },
    getUserLink: function() {
        const post = this.state.post
        const author = (post && post.author) ? post.author : null
        if (author && author.id) {
            return '/user/' + author.id
        }
        return ''
    },
    getPost: function(response) {
        const post = (response && response.user && response.user.id) ? response.user : null
        post.author = post
        return post
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    historyPush: function(o) {
        const { history } = this.props
        if (o && history && history.push) {
            history.push(o)
        } else {
            if (o) window.location.href = (o.search) ? o.pathname + '?' + o.search : o.pathname
        }
    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getPostStatus: function({ post }) {
        const { curlang } = this.props
        return getUserStatusData({ user: post, curlang })
    },
    getEditData: function() {
        const { editdata } = this.props
        const { stepIndex} = this.state
        const post = this.state.post
        return editdata.user({ user: post })[stepIndex]
    },

    //Dialog
    dialogClose: function() {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogClose) dialog.dialogClose()
    },
    dialogOpen: function({ action, title, text, submittext, canceltext }) {
        const dialog = this.refElements['dialog']
        if (dialog && dialog.dialogOpen) return dialog.dialogOpen({ action, title, text, submittext, canceltext })
        return {}
    },

    //Snack
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },

    readycallback: function() {
        const editpost = this.refElements['editpost']
        if (editpost && editpost.setReady) editpost.setReady()
    },

    //Publicicon
    getpublicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },

    getstatusname: function({ post }) {
        const { statusname } = this.getPostStatus({ post })
        return statusname
    },
    getdeletebutton: function({ post }) {
        const { deletebutton } = this.getPostStatus({ post })
        return deletebutton
    },
    getrestoretext: function({ post }) {
        const { curlang } = this.props
        const { deletebutton } = this.getPostStatus({ post })
        return (deletebutton) ? null : curlang.saveandrestore
    },
    render: function() {

        const { style, history, curlang = {}, getthumb, getthumbstyle } = this.props

        const {
            stepuserbasic = 'Basic data',
            stepuseravatar = 'Avatar'
        } = curlang

        const me = this.getMe()

        const savepost = this.savepost
        const nextstep = this.nextstep
        const deletepost = this.deletepost
        const restorepost = this.restorepost



        const state = this.state
        const data = this.getEditData()
        const post = this.state.post
        const close = this.close
        const setRef = this.setRef
        const author = (post && post.author) ? post.author : null

        const getpublicicon = this.getpublicicon
        const getstatusname = this.getstatusname
        const getdeletebutton = this.getdeletebutton
        const getrestoretext = this.getrestoretext
        const { fetch } = this.getHelpers()
        const { stepIndex } = state
        const getstep = this.getstep

        return (
            <TemplateDefault {...this.props} className={style.usercard}>
                {(me && me.isAdmin || me && me.isEditor) ?
                    <div>
                        <div className={style.stepper}>
                            {(state.orientation) ?
                                <Stepper activeStep={stepIndex} orientation={state.orientation}>
                                    <Step>
                                        <StepButton onClick={function() {
                                            getstep(0)
                                        }}>
                                            <StepLabel>{stepuserbasic}</StepLabel>
                                        </StepButton>
                                    </Step>
                                    <Step>
                                        <StepLabel>{stepuseravatar}</StepLabel>
                                    </Step>
                                </Stepper> : null}
                        </div>
                        <div>
                            <WapplrpostEdit history={history}
                                            style={ps}
                                            post={post}
                                            showauthor={true}
                                            avatarstyle={avs}
                                            setRef={setRef}
                                            className={'cardtype'}
                                            enablehiddencontent={false}
                                            save={(stepIndex < 1) ? nextstep : savepost}
                                            deletepost={deletepost}
                                            restorepost={restorepost}
                                            data={data}
                                            close={close}
                                            getstatusname={getstatusname}
                                            getdeletebutton={getdeletebutton}
                                            getrestoretext={getrestoretext}
                                            getpublicicon={getpublicicon}
                                            getthumb={getthumb}
                                            getthumbstyle={getthumbstyle}
                                            formprops={{
                                                user: author, me: me, fetch: fetch, setRef: function(a, e) {
                                                    setRef('formsy_' + a, e)
                                                }
                                            }}
                            />
                        </div>
                    </div>
                    :
                    null
                }
                <Wapplrsnackbar ref={function(e) {
                    setRef('snack', e)
                }} />
                <Wapplrdialog setRef={setRef} className={ps.dialog} />
            </TemplateDefault>
        )
    }
})
