export default function() {
    /*Set Routes*/
    return [
        {
            path: '/settings',
            data: {
                content: 'settings'
            }
        },
        {
            path: '*',
            data: {
                content: 'settings',
                callback: async function({ r, universalPath }) {
                    if (universalPath === '/') return r
                    r.status = 404
                    r.title = 'Not found'
                    return r
                }
            }
        }
    ]
}
