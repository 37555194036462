/*Import dependencies*/
import React from 'react'
import createReactClass from 'create-react-class'

/*Components*/
/*Templates*/
import ContentLogin from '../contents/content_login'
import ContentSignup from '../contents/content_signup'
import ContentForgot from '../contents/content_forgot'
import ContentReset from '../contents/content_reset'
import ContentConfirmSend from '../contents/content_confirm_send'
import ContentConfirmation from '../contents/content_confirm'

import ContentChangePassword from '../contents/content_change_password'
import ContentChangeEmail from '../contents/content_change_email'
import ContentChangeBasic from '../contents/content_change_basic'

import ContentDeleteAccount from '../contents/content_delete_account'
import ContentCustom from '../contents/content_custom'

/*Styles*/
import los from '../../themes/components/Wapplrlogin.css'
import msdark from '../../../../../../../themes/components/Wapplrmenu.css'
import ubs from '../../themes/components/Wapplruserbox.css'
import fos from '../../themes/components/Formsy.css'
import avs from '../../../../../../../themes/components/Wapplravatar.css'

/*Icons and Material theme*/
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme'

export default function(c) {

    const modulename = c.name || 'wapplrlogin'
    const history = c.history || {}
    const style = c.style || los
    const headerstyle = c.headerstyle || msdark
    const userboxstyle = c.userboxstyle || ubs
    const formsystyle = c.formsystyle || fos
    const avatarstyle = c.avatarstyle || avs
    const parentroute = c.parentroute
    const getthumb = c.getthumb
    const getthumbstyle = c.getthumbstyle
    const getavatarclassname = c.getavatarclassname
    const changeavatarhref = c.changeavatarhref
    const changeavatarhovertext = c.changeavatarhovertext

    lightBaseTheme.palette.primary1Color = c.palette && c.palette.primary1Color || '#3f454a'
    lightBaseTheme.palette.primary2Color = c.palette && c.palette.primary2Color || '#3f454a'

    const menus = c.menus || {
        [modulename]: [
            {
                name: 'Login', link: '/login',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (!user || user && !user.id)
                }
            },
            {
                name: 'Signup', link: '/signup',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (!user || user && !user.id)
                }
            }
        ],
        [modulename + '_fixeddrawer']: [
            {
                name: 'Logout', link: '/',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            { name: 'Forgot password', link: '/forgot' },
            {
                name: 'Email confirmation', link: '/confirmation/send',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id && !user.emailisvalidated)
                }
            },
            {
                name: 'Change email', link: '/changeemail',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: 'Change password', link: '/changepassword',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: 'Change name', link: '/changebasic',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            },
            {
                name: 'Delete Account', link: '/deleteaccount',
                role: function(props) {
                    const { sendprops = {} } = props
                    const { user } = sendprops
                    return (user && user.id)
                }
            }
        ]
    }

    const logindata = c.logindata || [
        {
            type: 'text',
            attr: {
                name: 'email',
                hintText: 'Email',
                floatingLabelText: 'Email',
                validations: 'isEmail',
                validationError: 'Please add a valid email',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button',
            attr: {
                type: 'submit',
                name: 'submit',
                label: 'Login',
                style: { marginTop: 32 },
                disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const signupdata = c.signupdata || [
        {
            type: 'text',
            attr: {
                name: 'lastname',
                hintText: 'Lastname',
                floatingLabelText: 'Lastname',
                validations: 'isSpecialWords',
                validationError: 'Please only use letters',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                name: 'firstname',
                hintText: 'Firstname',
                floatingLabelText: 'Firstname',
                validations: 'isSpecialWords',
                validationError: 'Please only use letters',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                name: 'email',
                hintText: 'Email',
                floatingLabelText: 'Email',
                validations: 'isEmail',
                validationError: 'Please add a valid email',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                validations: { minLength: 6 },
                validationError: 'Minimum 6 character',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'repeated_password',
                hintText: 'Confirm password',
                floatingLabelText: 'Confirm password',
                validations: 'equalsField:password',
                validationError: 'Password does not match the confirm password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button',
            attr: {
                type: 'submit',
                name: 'submit',
                label: 'Sign up',
                style: { marginTop: 32 },
                disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const resetdata = c.resetdata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                validations: { minLength: 6 },
                validationError: 'Minimum 6 character',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'repeated_password',
                hintText: 'Confirm password',
                floatingLabelText: 'Confirm password',
                validations: 'equalsField:password',
                validationError: 'Password does not match the confirm password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save new password'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const forgotdata = c.forgotdata || [
        {
            type: 'text',
            attr: {
                name: 'email',
                hintText: 'Email',
                floatingLabelText: 'Email',
                validations: 'isEmail',
                validationError: 'Please add a valid email',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Sending...' : 'Send confirm email'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const forgotdatawhenloggedin = c.forgotdatawhenloggedin || [
        {
            type: 'button', attr: {
                type: 'submit',
                name: 'submit',
                label: function(state) {
                    return (state.processing) ? 'Sending...' : 'Send confirmation email to set new password'
                },
                style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const confirmsenddata = c.confirmsenddata || [
        {
            type: 'button', attr: {
                type: 'submit',
                name: 'submit',
                label: function(state) {
                    return (state.processing) ? 'Sending...' : 'Resend confirmation email to validate your email address'
                },
                style: { marginLeft: 'auto', marginRight: 'auto', display: 'table', width: 'auto' },
                disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const changepassdata = c.changepassdata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'new_password',
                hintText: 'New password',
                floatingLabelText: 'New password',
                validations: { minLength: 6 },
                validationError: 'Minimum 6 character',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'repeated_password',
                hintText: 'Confirm new password',
                floatingLabelText: 'Confirm new password',
                validations: 'equalsField:new_password',
                validationError: 'Password does not match the confirm password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save new password'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const changeemaildata = c.changeemaildata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                name: 'email',
                hintText: 'Email',
                floatingLabelText: 'Email',
                validations: 'isEmail',
                validationError: 'Please add a valid email',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save new email'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const changebasicdata = c.changebasicdata || [
        {
            type: 'text',
            attr: {
                name: 'lastname',
                hintText: 'Lastname',
                floatingLabelText: 'Lastname',
                validations: 'isSpecialWords',
                validationError: 'Please only use letters',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'text',
            attr: {
                name: 'firstname',
                hintText: 'Firstname',
                floatingLabelText: 'Firstname',
                validations: 'isSpecialWords',
                validationError: 'Please only use letters',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Saving...' : 'Save data'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    const deleteaccountdata = c.deleteaccountdata || [
        {
            type: 'text',
            attr: {
                type: 'password',
                name: 'password',
                hintText: 'Password',
                floatingLabelText: 'Password',
                style: { width: '100%' },
                required: true
            }
        },
        {
            type: 'button', attr: {
                type: 'submit', name: 'submit', label: function(state) {
                    return (state.processing) ? 'Deleting...' : 'Delete'
                }, style: { marginTop: 32 }, disabled: function(state = {}) {
                    return !state.canSubmit
                }
            }
        }
    ]

    function login(data) {
        console.log(data)
    }

    function signup(data) {
        console.log(data)
    }

    function forgot(data, resetForm, invalidateForm, refs) {
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function reset() {
    }

    function logout(e, data) {
        console.log(data)
    }

    function confirmsend(data, resetForm, invalidateForm, refs) {
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function confirmation(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function changepass(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function changeemail(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function changebasic(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    function deleteaccount(data, resetForm, invalidateForm, refs) {
        console.log(data)
        const { processingStart, processingEnd } = refs
        if (processingStart && processingEnd) {
            processingStart(true)
            setTimeout(function() {
                processingEnd(false)
            }, 1500)
        }
    }

    if (c.login) login = c.login
    if (c.signup) signup = c.signup
    if (c.logout) logout = c.logout
    if (c.forgot) forgot = c.forgot
    if (c.reset) reset = c.reset
    if (c.confirmsend) confirmsend = c.confirmsend
    if (c.confirmation) confirmation = c.confirmation

    if (c.changepass) changepass = c.changepass
    if (c.changeemail) changeemail = c.changeemail
    if (c.changebasic) changebasic = c.changebasic

    if (c.deleteaccount) deleteaccount = c.deleteaccount

    const input = {

        menus: menus,
        style: style,
        headerstyle: headerstyle,
        avatarstyle: avatarstyle,
        userboxstyle: userboxstyle,
        formsystyle: formsystyle,
        history: history,
        parentroute: parentroute,
        muitheme: lightBaseTheme,
        modulename: modulename,

        logindata: logindata,
        signupdata: signupdata,
        forgotdata: forgotdata,
        forgotdatawhenloggedin: forgotdatawhenloggedin,
        resetdata: resetdata,
        confirmsenddata: confirmsenddata,
        deleteaccountdata: deleteaccountdata,

        changepassdata: changepassdata,
        changeemaildata: changeemaildata,
        changebasicdata: changebasicdata,

        loginsubmit: login,
        signupsubmit: signup,
        logoutsubmit: logout,
        forgotsubmit: forgot,
        resetsubmit: reset,
        confirmsendsubmit: confirmsend,
        confirmationsubmit: confirmation,

        changepasssubmit: changepass,
        changeemailsubmit: changeemail,
        changebasicsubmit: changebasic,

        deleteaccountsubmit: deleteaccount,

        resetmissingkeytext: c.resetmissingkeytext,

        confirmationisvalidated: c.confirmationisvalidated,
        confirmationmissingkeytext: c.confirmationmissingkeytext,
        confirmationprocessingtext: c.confirmationprocessingtext,
        confirmationloggedouttext: c.confirmationloggedouttext,
        confirmsendloggedouttext: c.confirmsendloggedouttext,
        deleteaccountloggedouttext: c.deleteaccountloggedouttext,

        loginheadertitle: c.loginheadertitle,
        signupheadertitle: c.signupheadertitle,
        logoutheadertitle: c.logoutheadertitle,
        confirmheadertitle: c.confirmheadertitle,
        forgotheadertitle: c.forgotheadertitle,
        resetheadertitle: c.resetheadertitle,
        deleteaccounttitle: c.deleteaccounttitle,

        confirmationsubtitle: c.confirmationsubtitle,
        loginsubtitle: c.loginsubtitle,
        confirmsendsubtitle: c.confirmsendsubtitle,
        forgotsubtitle: c.forgotsubtitle,
        resetsubtitle: c.resetsubtitle,
        signupsubtitle: c.signupsubtitle,
        deleteaccountsubtitle: c.deleteaccountsubtitle,

        logoutbuttontext: c.logoutbuttontext,

        changepasssubtitle: c.changepasssubtitle,
        changepassloggedouttext: c.changepassloggedouttext,
        changeemailsubtitle: c.changeemailsubtitle,
        changeemailloggedouttext: c.changeemailloggedouttext,
        changebasicsubtitle: c.changebasicsubtitle,
        changebasicloggedouttext: c.changebasicloggedouttext,
        changepassheadertitle: c.changepassheadertitle,
        changeemailheadertitle: c.changeemailheadertitle,
        changebasicheadertitle: c.changebasicheadertitle,
        deleteaccountheadertitle: c.deleteaccountheadertitle,

        forgotbuttonlabel: c.forgotbuttonlabel,
        forgotbuttonlink: c.forgotbuttonlink,

        getthumb: getthumb,
        getthumbstyle: getthumbstyle,
        getavatarclassname: getavatarclassname,
        changeavatarhref: changeavatarhref,
        changeavatarhovertext: changeavatarhovertext

        /*teststate: {
            response:{me:{id:1, name:{last:"Last", first:"First"}, emailisvalidated:false}},
            query: {
                confirmemailkey: "abc",
            }
        },*/

    }

    const templates = {
        loginpage: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentLogin {...input} construct={construct} />
            }
        }),
        signuppage: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentSignup {...input} construct={construct} />
            }
        }),
        forgotpage: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentForgot {...input} construct={construct} />
            }
        }),
        resetpage: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentReset {...input} construct={construct} />
            }
        }),
        confirmsendpage: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentConfirmSend {...input} construct={construct} />
            }
        }),
        confirmationpage: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentConfirmation {...input} construct={construct} />
            }
        }),
        changepassword: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentChangePassword {...input} construct={construct} />
            }
        }),
        changeemail: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentChangeEmail {...input} construct={construct} />
            }
        }),
        changebasic: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentChangeBasic {...input} construct={construct} />
            }
        }),
        deleteaccount: createReactClass({
            render() {
                const { construct } = this.props
                return <ContentDeleteAccount {...input} construct={construct} />
            }
        })
    }

    if (c.pages) {
        Object.keys(c.pages).map(function(k) {

            const { name, template, parentprops, title, ...rest } = c.pages[k]

            if (template && name && !templates[name]) {
                templates[name] = createReactClass({
                    render() {
                        const { construct } = this.props
                        return <ContentCustom {...input} construct={construct} CustomPage={template}
                                              parentprops={parentprops} {...rest} />
                    }
                })
            }

        })
    }

    return templates
}
