import React from 'react'
import createReactClass from 'create-react-class'

import TemplateDefault from '../templates/template_default'
import Formsy from '../components/Formsy'

export default createReactClass({
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { history, forgotbuttonlink } = this.props

        if (type === 'forgot') {
            if (history && history.push) {
                history.push(forgotbuttonlink)
            }
        }
        e.preventDefault()

    },
    render: function() {

        const {
            style,
            formsystyle,

            changepassdata,
            changepasssubmit,
            changepasssubtitle = 'Set your new password',
            changepassloggedouttext = 'Please log in before you trying change password',

            forgotbuttonlabel

        } = this.props

        const touchEnd = this.touchEnd
        const me = this.getMe()

        return (
            <TemplateDefault {...this.props} title={changepasssubtitle} slug='changepass'>
                {(me && me.id) ?
                    <div>
                        <Formsy formdata={changepassdata} submitForm={changepasssubmit} style={formsystyle} />
                        {(forgotbuttonlabel) ?
                            <div
                                onTouchTap={function(e) {
                                    touchEnd(e, 'click', 'click', { type: 'forgot' })
                                }}
                                onTouchStart={function() {
                                }}
                                className={style.forgotbuttoninloginpage}>
                                {forgotbuttonlabel}
                            </div>
                            : null
                        }
                    </div>
                    :
                    <div className={style.centerinfo}>
                        {changepassloggedouttext}
                    </div>
                }
            </TemplateDefault>
        )
    }
})
