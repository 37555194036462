import React from 'react'
import createReactClass from 'create-react-class'
import Subheader from 'material-ui/Subheader'
import wapplrcomponents from '../../../../../../components'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

const Wapplrcontent = wapplrcomponents.content

export default createReactClass({
    getInitialState() {
        return {
            isMounted: false
        }
    },
    componentDidMount: function() {
        this.setState({
            isMounted: true
        })
    },
    componentWillUnmount: function() {
        this.setState({
            isMounted: false
        })
    },
    render: function() {

        const { style, children, title, className, muitheme } = this.props

        return (
            <MuiThemeProvider muiTheme={getMuiTheme(muitheme)}>
                <Wapplrcontent style={style}>
                    <div className={style.listboxbg}>
                        {(title) ?
                            <div className={style.subheadercontainer}>
                                <Subheader className={style.subheader}>
                                    {title}
                                </Subheader>
                            </div> : null
                        }
                        <div className={className || style['page-padding']}>
                            {(children) ? children : null}
                        </div>
                    </div>
                </Wapplrcontent>
            </MuiThemeProvider>
        )
    }
})
