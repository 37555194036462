import React from 'react'
import createReactClass from 'create-react-class'
import TemplateCustom from '../templates/template_custom'
import Formsy from '../../../../../components/Formsy'
import Wapplruserbox from '../components/Wapplruserbox'

import Subheader from 'material-ui/Subheader'
import ContentSocial from './content_social'

import { decode } from 'querystring'

const Parent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}
        return {
            error: this.getErrorFromSearch()
        }
    },
    getErrorFromSearch: function() {
        const { history } = this.props
        let search = (history && history.location) ? history.location.search : ''
        if (search.slice(0, 1) === '?') search = search.slice(1)
        const so = (search) ? decode(search) : {}
        return (so.error) ? so.error : ''
    },
    getConstruct: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct : { props: { state: teststate } }
    },
    getHelpers: function() {
        const { construct } = this.props
        const { helpers } = construct.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers && helpers.store) ? helpers.store : null
        const states = (helpers && helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { construct, teststate = null } = this.props
        return (construct.props && construct.props.state) ? construct.props.state : teststate
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getUser: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.user) ? state.response.user : null
    },
    componentDidMount: function() {


    },
    setRef: function(a, e) {
        this.refElements[a] = e
    },
    snack: function(message) {
        const nodemessage = (message) ? <div>{message}</div> : <div />
        const snack = this.refElements['snack']
        if (snack && snack.open) snack.open(nodemessage)
    },
    touchEnd: function(e, fn, pt, d) {
        if (e && e.type === 'mouseup' || e && e.type === 'touchend' || e && e.type === pt) {
            const t = this
            if (t[fn]) t[fn](e, d)
        }
    },
    click: function(e, d) {
        const { type } = d
        const { history, forgotbuttonlink } = this.props

        if (type === 'forgot') {
            if (history && history.push) {
                history.push(forgotbuttonlink)
            }
        }
        e.preventDefault()

    },
    render: function() {

        const {
            style,
            formsystyle,
            loginsubtitle = 'Login with email',
            logindata,
            loginsubmit,
            userboxstyle,
            avatarstyle,
            logoutsubmit,
            history,
            logoutbuttontext,
            getthumb,
            getthumbstyle,
            getavatarclassname,
            forgotbuttonlabel
        } = this.props

        const me = this.getMe()
        const user = this.getUser()

        const construct = this.getConstruct()
        const state = this.state

        const { curlang = {} } = construct.props

        const {
            wapplrloginappsocialsubtitle = 'Login with another services'
        } = curlang

        const touchEnd = this.touchEnd
        const className = (!me || me && !me.id) ? style['page-padding'] : 'emp'

        return (
            <div>
                <TemplateCustom {...this.props} title={(!me || me && !me.id) ? loginsubtitle : null}
                                className={className}>
                    {(state.error && state.error !== 'facebookmissingemail') ?
                        <div className={style.errortext} style={{ padding: '0 10px 20px' }}>
                            {(curlang[state.error]) ? curlang[state.error] : null}
                        </div> : null
                    }
                    {(!me || me && !me.id) ?
                        <div>
                            <Formsy formdata={logindata} submitForm={loginsubmit} style={formsystyle} />
                            {(forgotbuttonlabel) ?
                                <div
                                    onTouchTap={function(e) {
                                        touchEnd(e, 'click', 'click', { type: 'forgot' })
                                    }}
                                    onTouchStart={function() {

                                    }}
                                    className={style.forgotbuttoninloginpage}>
                                    {forgotbuttonlabel}
                                </div>
                                : null
                            }
                        </div>
                        :
                        <Wapplruserbox style={userboxstyle} avatarstyle={avatarstyle} user={me} history={history}
                                       logoutsubmit={logoutsubmit} logoutbuttontext={logoutbuttontext}
                                       getthumb={getthumb}
                                       getthumbstyle={getthumbstyle}
                                       getavatarclassname={getavatarclassname}
                                       href={(me && user && me.id === user.id) ? '/user/' + user.id : null}
                        />
                    }
                </TemplateCustom>
                {(!me && false || me && !me.id && false) ?
                    <div className={style.socialinloginpagecontainer}>
                        <div className={style.subheadercontainer}>
                            <Subheader className={style.subheader}>
                                {wapplrloginappsocialsubtitle}
                            </Subheader>
                        </div>
                        <div className={className}>
                            <ContentSocial {...this.props} Parent={Parent} />
                        </div>
                    </div>
                    : null
                }
            </div>
        )
    }

})
